/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: #ffffff;
    li {
        list-style: none;
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 0 340px 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    // background: url(../images/bavv.jpg) top left no-repeat;
    @media (max-width:1465px) {
        padding: 0 250px 0 30px;
    }
}



.logo {
    width: 301px;
    height: 53px;
    background: url(../images/header_logo_vlc.png) top left no-repeat;
    background-size: contain;
    text-indent: -10000px;
    @media (max-width:1465px) {
        width: 250px;
        height: 43px;
    }
    @media (max-width:1100px) {
        width: 200px;
    }
    a {
        @include aLink();
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
    @media (max-width:1465px) {
        padding-left: 20px;
    }
    @media (max-width:991px) {
        padding-left: 0;
    }
  
    ul {
        display: flex;
        li{
            position: relative;
            text-align: center;
            width: 80px;
        }
        li:not(:last-child){
            margin-right: 59px;
            @media (max-width:1465px) {
                margin-right: 20px;
            }
        }
    }
    a{
        width: 100%;
        display: block;
        color: $hover;
        font-size: pxToEm(18);
        line-height: 1;
        letter-spacing: 1.8px;
        font-weight: 400;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            top: -31px;
            left: 50%;
            transform: translateX(-50%);
            height: 0;
            width: 0;
            border-top: 13px solid #ea9a03;
            border-left: 12px dashed transparent;
            border-right: 12px dashed transparent;
            transition: .4s ease;
            opacity: 0;
        }
        @media (min-width:768px) {
            &:hover{
                &::before{
                    opacity: 1;
                }
            }
        }
    }
}

.tfh-btn{
    position: absolute;
    right: 0;
    top: 0;
    width: 340px;
    height: 100%;
    background-color: $hover;
    @include fontset(26,3,0,700,$org);
    text-align: center;
    overflow: hidden;
    @media (max-width:1465px) {
        width: 270px;
    }
    @media (max-width:991px){
        display: none;
    }
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        border-top: 80px solid #fff;
        border-left: 0px dashed transparent;
        border-right: 50px dashed transparent;
        z-index: 5;
    }
    &::after{
        position: absolute;
        content: '';
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $org;
        transition: .6s ease;
        z-index: 3;
    }
    span{
        position: relative;
        padding-left: 52px;
        z-index: 4;
        left: 15px;
        img{
            position: absolute;
            top: 0;
            left: 0;
            transition: .4s ease;
        }
        .un{
            opacity: 1;
        }
        .ho{
            opacity: 0;
        }
    }
    @media (min-width:768px) {
        &:hover{
            color: $hover;
            &::after{
                left: 0;
            }
            span{
                .un{
                    opacity: 0;
                }
                .ho{
                    opacity: 1;
                }
            }
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 29px;
$bar-height: 2px;
$bar-spacing: 8px;
$bar-color:$org;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 85px;
    height: 100%;
    cursor: pointer;
    z-index: 10;
    background-color: $hover;
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        border-top: 60px solid #fff;
        border-left: 0px dashed transparent;
        border-right: 30px dashed transparent;
        z-index: 5;
    }
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
    width: $bar-width;
    height: $bar-height;
   

    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translate(-50%,$bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    left: 65%;
    top: 21px;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: $bar-color;
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    background: $bar-color;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

.hamburger-menu.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    ;
    @include border(99px);
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@media (max-width:991px){
    $header_heright: 60px;

    .logo{
        width: 192px;
        height: 34px;
    }
    .header_show{
    }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 22px;
    }
    .nav_box nav{
        width: 100%;
        margin: auto;
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  $header_heright;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: $hover;
        ul {
            display: block;
            padding: 0 20px;
            padding-top: 20px;
            li {
                width: 100%;
                &:last-child{
                    display: block;
                }
                &:not(:last-child){
                    border-bottom: 1px solid #002d72;
                }
                a {
                    padding: 28px 15px;
                    text-align: left;
                    text-decoration: none;
                    color: #fff;
                    padding-left: 0;
                    font-size: pxToEm(18);
                    &::before{
                        top: 50%;
                        transform: translateY(-50%);
                        left: auto;
                        right: -11px;
                        opacity: 1;
                        border-left: 12px solid #ea9a03;
                        border-top: 6px dashed transparent;
                        border-bottom: 6px dashed transparent;
                    }
                    span{
                        display: inline-block;
                        margin-left: 16px;
                        @include fontset(14,1,0,400,$org);
                    }
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}


