// 商品頁

.productPage{
    .sh-insdtit .ti:before{
        @media (max-width:767px) {
            right: -35px;
        }
    }
    .item1{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 50px;
        }
        .it1-list{
            margin-top: 60px;
            display: flex;
            flex-wrap: wrap;
            @media (max-width:991px) and (min-width:768px) {
                justify-content: space-between;
             }
             @media (max-width:767px) {
                 display: block;
                 margin-top: 30px;
             }
            li{
                width: 31%;
                text-align: center;
                margin-bottom: 90px;
                @media (min-width:991px) {
                    &:not(:nth-child(3n)){
                        margin-right: 3.5%;
                    }
                }
                @media (max-width:991px) and (min-width:768px) {
                   width: 45%;
                }
                @media (max-width:767px) {
                    width: 100%;
                    margin-bottom: 45px;
                }
                @media (min-width:768px) {
                    &:nth-child(3n){
                        transition-delay: .6s;
                    }
                    &:nth-child(3n-1){
                        transition-delay: .3s;
                    }
                    &:nth-child(3n-2){
                        
                    }
                   
                    &:hover{
                        .img-bx{
                            opacity: .5;
                        }
                        .des-bx .des{
                            color: $hover;
                        }
                        .more-btn::after{
                            width: 100%;
                        }
                    }
                }
                .img-bx{
                    width: 85%;
                    margin: 0 auto;
                    transition: .6s ease;
                    @media (max-width:767px) {
                        width: 65%;
                    }
                }
                .des-bx{
                    margin-top: 5px;
                    @media (max-width:767px) {
                        margin-top: 10px;
                    }
                    .ti{
                        @include fontset(22,1.6,0,400,$hover);
                        @media (max-width:767px) {
                            font-size: pxToEm(18);
                        }
                    }
                    .des{
                        @include fontset(18,1.6,0,400,#333333);
                        transition: .4s ease;
                        @media (max-width:767px) {
                            font-size: pxToEm(15);
                        }
                    }
                } 
                .more-btn{
                    width: 120px;
                    height: 40px;
                    @include fontset(18,2.2,0,300,#fff);
                    margin: 0 auto;
                    margin-top: 25px;
                    transition: .4s ease;
                    position: relative;
                    @media (max-width:767px) {
                        width: 105px;
                        height: 35px;
                        font-size: pxToEm(15);
                        margin-top: 12px;
                    }
                    span{
                        position: relative;
                        z-index: 5;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $hover;
                        z-index: 3;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 100%;
                        transition: .4s ease;
                        z-index: 4;
                        background-color: $org;
                    }
                }
            }
        }
    }
}


.productdetailPage{
    .item1{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 25px;
        }
        .sh-insdtit .ti:before{
            right: -65px;
            @media (max-width:767px) {
                right: -10px;
            }
        }
        .sh-insdtit .des{
            @include fontset(22,1.7,0,400,$hover);
            margin-top: 7px;
            @media (max-width:767px) {
                font-size: pxToEm(15);
            }
        }
        .pro-img{
            text-align: center;
            margin-top: 40px;
            margin-bottom: 40px;
            @media (max-width:767px) {
                width: 65%;
                margin: 0 auto;
                margin-top: 25px;
            }
        }
        .editor_Content{
            max-width: 1037px;
            width: 100%;
            padding: 0 16px;
            margin: 0 auto;
            margin-bottom: 80px;
            @media (max-width:767px) {
                margin-top: 15px;
                margin-bottom: 40px;
                padding: 0;
            }
        }
        .sh-morebtn{
            width: 285px;
            height: 70px;
            margin-bottom: 90px;
            @media (max-width:767px) {
                width: 195px;
                height: 45px;
                margin-bottom: 75px;
            }
            span{
                @media (max-width:767px) {
                    line-height: 2.6;
                }
            }
        }
    }
}

.casePage{
    .container{
        @media (min-width:1199px) {
            max-width: 1038px;
        }
    }
    .item1{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 50px;
        }
        .sh-insdtit{
            margin-bottom: 50px;
        }
        .editor_Content{
            margin-bottom: 105px;
            @media (max-width:767px) {
                margin-bottom: 50px;
            }
        }
    }
}


.contactPage{
    .sh-insdtit .ti:before{
        right: 65px;
        @media (max-width:767px) {
            right: 25px;
            top: 0;
        }
    }
    .sh-insdtit .ti{
        @media (max-width:767px) {
            font-size: pxToEm(15);
            line-height: 1.7;
        }
    }
    .sh-insdtit{
        margin-top: 80px;
        @media (max-width:767px) {
            margin-top: 40px;
        }
    }
    .item1{
        margin-top: 80px;
        position: relative;
        margin-bottom: 120px;
        background: url(../images/contact/b_bg.jpg) no-repeat center center / 1370px;
        background-position-y: 30px;
        @media (max-width:1370px) {
            overflow: hidden;
          
        }
        &::before{
            position: absolute;
            content: '';
            // background: url(../images/contact/b_bg.jpg) no-repeat center center / cover;
            width: 1370px;
            // width: 100%;
            top: 30px;
            height: calc(100% - 85px);
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
        @media (max-width:767px) {
            margin-top: 35px;
            margin-bottom: 60px;
        }
        @media (max-width:500px) {
            background: url(../images/contact/b_bg_mb.jpg) no-repeat center center / cover;
            background-position-y: 200px;
            background-size: contain;
        }
        .it1-bx{
         
          
            position: relative;
           
            @media (max-width:767px) {
                margin-top: 40px;
               
            }
        }
        .flex-bx{
            margin-top: 45px;
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
            @media (max-width:767px) {
                margin-top: 25px;
            }
            .l{
                width: 37%;
                @media (max-width:991px) {
                    width: 65%;
                    margin: 0 auto;
                }
                .bgcover{
                    padding-bottom: 450px;
                    background-size: cover !important;
                    @media (max-width:767px) {
                        padding-bottom: 225px;
                    }
                }
            }
            .r{
                width: 58%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .con-ti{
                    max-width: 337px;
                    @media (max-width:991px) {
                        max-width: 83%;
                        margin: 0 auto;
                        margin-top: 35px;
                    }
                }
                .conlist{
                    margin-top: 50px;
                    margin-bottom: 40px;
                    display: flex;
                    flex-wrap: wrap;
                    @media (max-width:767px) {
                        display: block;
                        margin-top: 30px;
                        margin-bottom: 50px;
                    }
                    li:nth-child(1),li:nth-child(3){
                        width: 60%;
                        @media (max-width:767px) {
                            width: 100%;
                        }
                    }
                    li:nth-child(2){
                        width: 40%;
                        @media (max-width:767px) {
                            width: 100%;
                        }
                    }

                    li{
                        margin-bottom: 30px;
                        @media (max-width:767px) {
                            text-align: center;
                            margin-bottom: 25px;
                        }
                        .des-ti{
                            @include fontset(22,1.64,0,700,$hover);
                            @media (max-width:767px) {
                                font-size: pxToEm(15);
                            }
                        }
                        a,.adr{
                            @include fontset(22,1.64,0,400);
                            @media (max-width:767px) {
                                font-size: pxToEm(15);
                            }
                        }
                        .adr{
                            img{
                                margin-left: 6px;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                }
                .link-bx{
                    display: flex;
                    width:calc(100% + 16px);
                    margin-left: -8px;
                    @media (max-width:1199px) {
                        flex-wrap: wrap;
                    }
                   
                    a{
                        display: block;
                        width: 25%;
                        height: 85px;
                        @include fontset(20,1.4,0,700,$org);
                        position: relative;
                        text-align: center;
                        padding-top: 15px;
                        margin: 0 8px;
                        padding-left: 3px;
                        padding-right: 10px;
                        @media (max-width:1199px) {
                            margin-bottom: 13px;
                            width: 32%;
                        }
                        @media (max-width:991px) {
                            width: 45%;
                        }
                        @media (max-width:767px) {
                            font-size: pxToEm(18);
                            height: 70px;
                            padding-top: 10px;
                        }
                        @media (max-width:350px) {
                            padding-left: 2px;
                            padding-right: 2px;
                            width: 44%;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: $hover;
                            z-index: 3;
                            transform: skewX(-14deg);
                            @media (max-width:767px) {
                                transform: skewX(-14deg);
                            }
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 0;
                            height: 100%;
                            transition: .4s ease;
                            z-index: 4;
                            background-color: $org;
                            transform: skewX(-25deg);
                        }
                        span{
                            position: relative;
                            z-index: 5;
                        }
                        i{
                            font-weight: 400;
                            color: #fff;
                            display: block;
                            position: relative;
                            z-index: 5;
                        }
                    }
                }
            }
        }
    }
}





