@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&family=Noto+Sans+TC:wght@300;400;500;700&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(../images/mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #244981; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Inria Sans" "Noto Sans TC"; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: 18px; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: 18px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC";
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 80px; }
  @media (max-width: 991px) {
    main[role="main"] {
      margin-top: 60px; } }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  font-size: 1.125rem;
  line-height: 2;
  color: #333333; }
  .editor_Content ol {
    list-style-type: decimal;
    padding-left: 10px; }
  .editor_Content ul {
    list-style-type: initial;
    padding-left: 20px; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold;
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #000000; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: 100% !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn.show-topbtn {
  opacity: 1; }

.top_btn {
  opacity: 0;
  cursor: pointer;
  transition: .4s ease;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 8;
  font-size: 0.75rem;
  z-index: 9;
  width: 71px;
  height: 72px;
  background: url(../images/top.png) no-repeat center center/57px;
  transform: scale(1.2); }
  @media (min-width: 768px) {
    .top_btn:hover {
      transform: scale(1);
      background: url(../images/top-hover.png) no-repeat center center/48px; } }
  @media (max-width: 767px) {
    .top_btn {
      width: 53px;
      height: 53px;
      background: url(../images/top.png) no-repeat center center/53px;
      transform: scale(1);
      bottom: 65px; } }
  .top_btn.fix {
    position: absolute;
    bottom: 40px; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: -75px;
        transition: 0s; } }

/*----------------------頁數------------------------*/
.page {
  font-size: 1.875rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 55px;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .page {
      margin-bottom: 85px;
      font-size: 1.375rem; } }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block;
    width: 7%; }
    .page dt a,
    .page dd a {
      display: block;
      height: 35px;
      line-height: 30px;
      text-decoration: none;
      color: #aaaaaa;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      border-radius: 0; }
      @media (min-width: 767px) {
        .page dt a:hover,
        .page dd a:hover {
          color: #ea9a03; } }
  .page dd {
    width: 4.3%; }
    @media (max-width: 767px) {
      .page dd {
        width: 15%; } }
    @media (max-width: 350px) {
      .page dd {
        width: 10% !important; } }
  .page dt {
    width: auto; }
  .page dd.active a {
    background: none;
    padding: 0;
    color: #ea9a03; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    transition: 0.4s;
    position: relative;
    top: -1px;
    background: url(../images/arrow2.png) no-repeat center center/9px; }
    @media (max-width: 767px) {
      .page dt.rtbn a,
      .page dt.ltbn a {
        top: -1px;
        background: url(../images/arrow2.png) no-repeat center center/9px; } }
  .page dt.ltbn a {
    top: 1px; }
    @media (min-width: 767px) {
      .page dt.ltbn a:hover {
        background: url(../images/arrow2.png) no-repeat center center/9px;
        border-color: rgba(255, 255, 255, 0); } }
    @media (max-width: 767px) {
      .page dt.ltbn a {
        left: -10px; } }
  .page dt.rtbn a {
    transform: rotate(180deg); }
    @media (min-width: 767px) {
      .page dt.rtbn a:hover {
        background: url(../images/arrow2.png) no-repeat center center/9px;
        border-color: rgba(255, 255, 255, 0); } }
    @media (max-width: 767px) {
      .page dt.rtbn a {
        margin-left: 0;
        right: -7px; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 767px) {
  .page {
    font-size: 1.375rem;
    margin-top: 60px;
    margin-bottom: 65px; }
    .page dd {
      width: 14%; }
    .page dt.ltbn a {
      background-size: 7px;
      top: auto; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      background-size: 7px;
      top: -2px; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a,
    .page dt.rtbn a {
      width: 28px;
      height: 28px; }
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #ffffff; }
  .header_show li {
    list-style: none; }

.header_box {
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0 340px 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem; }
  @media (max-width: 1465px) {
    .header_box {
      padding: 0 250px 0 30px; } }

.logo {
  width: 301px;
  height: 53px;
  background: url(../images/header_logo_vlc.png) top left no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  @media (max-width: 1465px) {
    .logo {
      width: 250px;
      height: 43px; } }
  @media (max-width: 1100px) {
    .logo {
      width: 200px; } }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative; }
  @media (max-width: 1465px) {
    .nav_box {
      padding-left: 20px; } }
  @media (max-width: 991px) {
    .nav_box {
      padding-left: 0; } }
  .nav_box ul {
    display: flex; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      width: 80px; }
    .nav_box ul li:not(:last-child) {
      margin-right: 59px; }
      @media (max-width: 1465px) {
        .nav_box ul li:not(:last-child) {
          margin-right: 20px; } }
  .nav_box a {
    width: 100%;
    display: block;
    color: #244981;
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: 1.8px;
    font-weight: 400;
    position: relative; }
    .nav_box a::before {
      position: absolute;
      content: '';
      top: -31px;
      left: 50%;
      transform: translateX(-50%);
      height: 0;
      width: 0;
      border-top: 13px solid #ea9a03;
      border-left: 12px dashed transparent;
      border-right: 12px dashed transparent;
      transition: .4s ease;
      opacity: 0; }
    @media (min-width: 768px) {
      .nav_box a:hover::before {
        opacity: 1; } }

.tfh-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 340px;
  height: 100%;
  background-color: #244981;
  font-size: 1.625rem;
  line-height: 3;
  letter-spacing: 0px;
  font-weight: 700;
  color: #ea9a03;
  text-align: center;
  overflow: hidden; }
  @media (max-width: 1465px) {
    .tfh-btn {
      width: 270px; } }
  @media (max-width: 991px) {
    .tfh-btn {
      display: none; } }
  .tfh-btn::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    border-top: 80px solid #fff;
    border-left: 0px dashed transparent;
    border-right: 50px dashed transparent;
    z-index: 5; }
  .tfh-btn::after {
    position: absolute;
    content: '';
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ea9a03;
    transition: .6s ease;
    z-index: 3; }
  .tfh-btn span {
    position: relative;
    padding-left: 52px;
    z-index: 4;
    left: 15px; }
    .tfh-btn span img {
      position: absolute;
      top: 0;
      left: 0;
      transition: .4s ease; }
    .tfh-btn span .un {
      opacity: 1; }
    .tfh-btn span .ho {
      opacity: 0; }
  @media (min-width: 768px) {
    .tfh-btn:hover {
      color: #244981; }
      .tfh-btn:hover::after {
        left: 0; }
      .tfh-btn:hover span .un {
        opacity: 0; }
      .tfh-btn:hover span .ho {
        opacity: 1; } }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 85px;
  height: 100%;
  cursor: pointer;
  z-index: 10;
  background-color: #244981; }
  .menu-wrapper::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    border-top: 60px solid #fff;
    border-left: 0px dashed transparent;
    border-right: 30px dashed transparent;
    z-index: 5; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 29px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  transform: translate(-50%, 8px);
  background: #ea9a03;
  transition: all 0ms 300ms;
  left: 65%;
  top: 21px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  background: #ea9a03;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  background: #ea9a03;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991px) {
  .logo {
    width: 192px;
    height: 34px; }
  .header_box {
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 22px; }
  .nav_box nav {
    width: 100%;
    margin: auto; }
  .nav_box {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #244981; }
    .nav_box ul {
      display: block;
      padding: 0 20px;
      padding-top: 20px; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li:last-child {
          display: block; }
        .nav_box ul li:not(:last-child) {
          border-bottom: 1px solid #002d72; }
        .nav_box ul li a {
          padding: 28px 15px;
          text-align: left;
          text-decoration: none;
          color: #fff;
          padding-left: 0;
          font-size: 1.125rem; }
          .nav_box ul li a::before {
            top: 50%;
            transform: translateY(-50%);
            left: auto;
            right: -11px;
            opacity: 1;
            border-left: 12px solid #ea9a03;
            border-top: 6px dashed transparent;
            border-bottom: 6px dashed transparent; }
          .nav_box ul li a span {
            display: inline-block;
            margin-left: 16px;
            font-size: 0.875rem;
            line-height: 1;
            letter-spacing: 0px;
            font-weight: 400;
            color: #ea9a03; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

footer {
  z-index: -10;
  padding: 50px 55px 42px 60px;
  background-color: #244981; }
  @media (max-width: 767px) {
    footer {
      padding: 23px 20px;
      padding-bottom: 85px; } }
  footer .f-bx {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
    @media (max-width: 767px) {
      footer .f-bx {
        display: block; } }
    footer .f-bx .l {
      width: 88%; }
      @media (max-width: 767px) {
        footer .f-bx .l {
          width: 100%; } }
      footer .f-bx .l .flex-bx {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap; }
        @media (max-width: 767px) {
          footer .f-bx .l .flex-bx {
            display: block; } }
        footer .f-bx .l .flex-bx .t {
          margin-right: 3.5%; }
          footer .f-bx .l .flex-bx .t .f-logo {
            width: 337px;
            margin-bottom: 20px; }
            @media (max-width: 767px) {
              footer .f-bx .l .flex-bx .t .f-logo {
                width: 240px;
                margin-bottom: 10px; } }
          footer .f-bx .l .flex-bx .t .env {
            font-size: 1.125rem;
            line-height: 1.91;
            letter-spacing: 0px;
            font-weight: 400;
            color: #fff; }
            @media (max-width: 767px) {
              footer .f-bx .l .flex-bx .t .env {
                font-size: 0.8125rem; } }
          footer .f-bx .l .flex-bx .t .copyright, footer .f-bx .l .flex-bx .t .mak {
            font-size: 0.875rem;
            line-height: 1.4;
            letter-spacing: 0px;
            font-weight: 400;
            color: #ffffff; }
        footer .f-bx .l .flex-bx .link-bx {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px; }
          @media (max-width: 1250px) {
            footer .f-bx .l .flex-bx .link-bx {
              margin-bottom: -20px; } }
          footer .f-bx .l .flex-bx .link-bx a {
            padding: 20px 30px;
            border: 1px solid #fff;
            text-align: center;
            border-radius: 30px;
            display: block; }
            @media (max-width: 1250px) {
              footer .f-bx .l .flex-bx .link-bx a {
                margin-bottom: 20px; } }
            @media (max-width: 767px) {
              footer .f-bx .l .flex-bx .link-bx a {
                padding: 10px 10px;
                margin-bottom: 15px; } }
            @media (min-width: 768px) {
              footer .f-bx .l .flex-bx .link-bx a:hover {
                background-color: #fff; }
                footer .f-bx .l .flex-bx .link-bx a:hover span {
                  color: #244981; } }
            footer .f-bx .l .flex-bx .link-bx a span {
              font-size: 1.125rem;
              line-height: 0.5;
              letter-spacing: 0px;
              font-weight: 400;
              color: #ffffff; }
              @media (max-width: 767px) {
                footer .f-bx .l .flex-bx .link-bx a span {
                  font-size: 0.875rem; } }
            footer .f-bx .l .flex-bx .link-bx a:not(:last-child) {
              margin-right: 20px; }
              @media (max-width: 767px) {
                footer .f-bx .l .flex-bx .link-bx a:not(:last-child) {
                  margin-right: 10px; } }
    footer .f-bx .r {
      width: auto; }
      @media (max-width: 767px) {
        footer .f-bx .r {
          margin-top: 35px; } }
      footer .f-bx .r .g-bx {
        display: flex; }
        footer .f-bx .r .g-bx a {
          width: 40px;
          display: block;
          position: relative; }
          @media (max-width: 767px) {
            footer .f-bx .r .g-bx a {
              width: 32px; } }
          footer .f-bx .r .g-bx a::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(36, 73, 129, 0.4);
            opacity: 0;
            transition: .4s ease; }
          footer .f-bx .r .g-bx a:not(:last-child) {
            margin-right: 20px; }
            @media (max-width: 767px) {
              footer .f-bx .r .g-bx a:not(:last-child) {
                margin-right: 35px; } }
          footer .f-bx .r .g-bx a:first-child {
            width: 53px; }
            @media (max-width: 767px) {
              footer .f-bx .r .g-bx a:first-child {
                width: 43px; } }
          @media (min-width: 768px) {
            footer .f-bx .r .g-bx a:hover::before {
              opacity: 1; } }

.f-telbtn {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 55px;
  font-size: 1.375rem;
  line-height: 2.8;
  letter-spacing: 0px;
  font-weight: 700;
  color: #244981;
  background-color: #ea9a03;
  text-align: center;
  z-index: 999; }
  .f-telbtn span {
    position: relative;
    padding-left: 50px;
    font-size: 1.375rem;
    line-height: 2.8;
    letter-spacing: 0px;
    font-weight: 700;
    color: #244981; }
    .f-telbtn span img {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px; }

/******************************************/
/*		pages
/******************************************/
.indexPage .banner-bx {
  width: 100%;
  height: auto;
  overflow: hidden; }
  .indexPage .banner-bx .bgcover {
    height: 0;
    background-size: cover !important;
    padding-bottom: 31.25%; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .bgcover {
        padding-bottom: 162%; } }
  .indexPage .banner-bx a {
    display: block; }
  .indexPage .banner-bx .slick-dots {
    text-align: center;
    right: 0;
    bottom: 25px; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .slick-dots {
        text-align: center;
        right: auto;
        bottom: 75px; } }
    .indexPage .banner-bx .slick-dots li.slick-active button:before {
      background-color: #ffffff; }
    .indexPage .banner-bx .slick-dots li {
      width: 40px;
      height: 40px !important;
      margin: 0 5px; }
      .indexPage .banner-bx .slick-dots li button {
        width: 40px;
        height: 40px !important;
        padding: 0; }
        .indexPage .banner-bx .slick-dots li button::before {
          width: 15px;
          height: 15px;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0);
          transition: .4s;
          border: 2px solid #ffffff; }
  .indexPage .banner-bx .slick-prev {
    width: 25px;
    height: 60px;
    left: 55px;
    background: url(../images/arrow_l.png) no-repeat center center/contain; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .slick-prev {
        display: none !important; } }
  .indexPage .banner-bx .slick-next {
    width: 25px;
    height: 60px;
    right: 55px;
    background: url(../images/arrow_r.png) no-repeat center center/contain; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .slick-next {
        display: none !important; } }

.indexPage .item1 {
  margin-top: 95px; }
  @media (max-width: 767px) {
    .indexPage .item1 {
      margin-top: 45px; } }
  .indexPage .item1 .sh-des {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 45px; }
    @media (max-width: 767px) {
      .indexPage .item1 .sh-des {
        margin-top: 15px;
        margin-bottom: 35px;
        text-align: center; }
        .indexPage .item1 .sh-des br {
          display: block; } }
  .indexPage .item1 .it1-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .indexPage .item1 .it1-list {
        margin-top: 40px; } }
    .indexPage .item1 .it1-list li {
      width: 16.66666%;
      margin-bottom: 60px; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-list li {
          width: 50%;
          margin-bottom: 30px; } }
      @media (min-width: 767px) {
        .indexPage .item1 .it1-list li:nth-child(2) {
          transition-delay: .2s; }
        .indexPage .item1 .it1-list li:nth-child(3) {
          transition-delay: .4s; }
        .indexPage .item1 .it1-list li:nth-child(4) {
          transition-delay: .6s; }
        .indexPage .item1 .it1-list li:nth-child(5) {
          transition-delay: .8s; }
        .indexPage .item1 .it1-list li:nth-child(6) {
          transition-delay: 1s; }
        .indexPage .item1 .it1-list li:nth-child(7) {
          transition-delay: 1.2s; }
        .indexPage .item1 .it1-list li:nth-child(8) {
          transition-delay: 1.4s; }
        .indexPage .item1 .it1-list li:nth-child(9) {
          transition-delay: 1.6s; }
        .indexPage .item1 .it1-list li:nth-child(10) {
          transition-delay: 1.8s; }
        .indexPage .item1 .it1-list li:nth-child(11) {
          transition-delay: 2s; }
        .indexPage .item1 .it1-list li:nth-child(12) {
          transition-delay: 2.2s; } }
      .indexPage .item1 .it1-list li .img-bx {
        position: relative;
        text-align: center; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1-list li .img-bx {
            width: 50%;
            margin: 0 auto; } }
        .indexPage .item1 .it1-list li .img-bx .hov {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: .4s ease;
          opacity: 0; }
      .indexPage .item1 .it1-list li .des {
        font-size: 1.375rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 400;
        color: #244981;
        text-align: center;
        transition: .4s ease;
        margin-top: 25px; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1-list li .des {
            font-size: 1.125rem;
            margin-top: 17px; } }
      @media (min-width: 768px) {
        .indexPage .item1 .it1-list li:hover .img-bx .hov {
          opacity: 1; }
        .indexPage .item1 .it1-list li:hover .des {
          color: #ea9a03; } }
  .indexPage .item1 .tw-bx {
    display: flex;
    margin-top: 90px; }
    @media (max-width: 767px) {
      .indexPage .item1 .tw-bx {
        margin-top: 20px;
        display: block; } }
    .indexPage .item1 .tw-bx a {
      display: block;
      position: relative;
      width: 50%; }
      @media (min-width: 768px) {
        .indexPage .item1 .tw-bx a:hover::before {
          opacity: 0; } }
      @media (max-width: 767px) {
        .indexPage .item1 .tw-bx a {
          width: 100%; } }
      .indexPage .item1 .tw-bx a::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .4s ease;
        background-color: rgba(36, 73, 129, 0.7); }
      .indexPage .item1 .tw-bx a .bgcover {
        height: 250px; }
        @media (max-width: 767px) {
          .indexPage .item1 .tw-bx a .bgcover {
            height: 125px;
            background-size: cover !important; } }
      .indexPage .item1 .tw-bx a .tx-bx {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        padding-left: 90px; }
        .indexPage .item1 .tw-bx a .tx-bx::before {
          position: absolute;
          content: '';
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          border-left: 24px solid #ea9a03;
          border-top: 12px dashed transparent;
          border-bottom: 12px dashed transparent; }
          @media (max-width: 767px) {
            .indexPage .item1 .tw-bx a .tx-bx::before {
              border-left: 12px solid #ea9a03;
              border-top: 6px dashed transparent;
              border-bottom: 6px dashed transparent; } }
        @media (max-width: 767px) {
          .indexPage .item1 .tw-bx a .tx-bx {
            padding-left: 55px; } }
        .indexPage .item1 .tw-bx a .tx-bx img {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
          @media (max-width: 767px) {
            .indexPage .item1 .tw-bx a .tx-bx img {
              width: 12%; } }
        .indexPage .item1 .tw-bx a .tx-bx .en-des, .indexPage .item1 .tw-bx a .tx-bx .des {
          font-size: 1.375rem;
          line-height: 1.64;
          letter-spacing: 1.1px;
          font-weight: 400;
          color: #fff; }
          @media (max-width: 767px) {
            .indexPage .item1 .tw-bx a .tx-bx .en-des, .indexPage .item1 .tw-bx a .tx-bx .des {
              font-size: 1.125rem;
              line-height: 1.3; } }
      .indexPage .item1 .tw-bx a:nth-child(2) .tx-bx {
        padding-left: 100px; }
        @media (max-width: 767px) {
          .indexPage .item1 .tw-bx a:nth-child(2) .tx-bx {
            padding-left: 55px; } }
        .indexPage .item1 .tw-bx a:nth-child(2) .tx-bx img {
          width: 62px;
          height: 62px; }
          @media (max-width: 767px) {
            .indexPage .item1 .tw-bx a:nth-child(2) .tx-bx img {
              width: 12%;
              height: auto; } }

.indexPage .item2 {
  margin-top: 150px;
  overflow: hidden;
  position: relative; }
  @media (max-width: 767px) {
    .indexPage .item2 {
      margin-top: 50px; } }
  .indexPage .item2::before {
    position: absolute;
    content: '';
    background: url(../images/c_bg.jpg) no-repeat center center/cover;
    height: calc(100% - 55px);
    top: 20px;
    width: 100%;
    z-index: -1; }
    @media (max-width: 767px) {
      .indexPage .item2::before {
        top: 25px;
        height: calc(100% - 45px);
        background: url(../images/c_bg_mb.jpg) no-repeat center center/cover; } }
  .indexPage .item2 .it2-list {
    margin-top: 95px;
    margin-bottom: 100px;
    width: calc(100% + 40px);
    left: -20px; }
    @media (max-width: 767px) {
      .indexPage .item2 .it2-list {
        left: auto;
        width: 86%;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px; } }
    .indexPage .item2 .it2-list li {
      margin: 0 18px;
      padding: 30px 0;
      transition: .4s; }
      .indexPage .item2 .it2-list li .img-bx {
        margin-bottom: -1px; }
      .indexPage .item2 .it2-list li .des {
        font-size: 1.25rem;
        line-height: 1.3;
        letter-spacing: 1px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        padding: 20px 0;
        background-color: #244981;
        transition: .4s ease; }
        @media (max-width: 767px) {
          .indexPage .item2 .it2-list li .des {
            padding: 15px 0; } }
      @media (min-width: 768px) {
        .indexPage .item2 .it2-list li:hover {
          transform: scale(1.15); }
          .indexPage .item2 .it2-list li:hover .des {
            background-color: #ea9a03;
            color: #244981; } }
    .indexPage .item2 .it2-list .slick-prev {
      width: 25px;
      height: 60px;
      left: 55px;
      background: url(../images/arrow_l.png) no-repeat center center/contain; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-list .slick-prev {
          height: 30px;
          left: -15px;
          background: url(../images/mbarrow_l.png) no-repeat center center/contain; } }
    .indexPage .item2 .it2-list .slick-next {
      width: 25px;
      height: 60px;
      right: 55px;
      background: url(../images/arrow_r.png) no-repeat center center/contain; }
      @media (max-width: 767px) {
        .indexPage .item2 .it2-list .slick-next {
          height: 30px;
          right: -15px;
          background: url(../images/mbarrow_r.png) no-repeat center center/contain; } }
    .indexPage .item2 .it2-list .slick-track {
      margin: 0 auto; }
  .indexPage .item2 .in_fade.sh-morebtn {
    opacity: 1;
    top: 0; }
    .indexPage .item2 .in_fade.sh-morebtn span {
      color: rgba(255, 255, 255, 0);
      transition: .4s ease; }
    .indexPage .item2 .in_fade.sh-morebtn::before {
      width: 0;
      transition: .6s ease; }
  .indexPage .item2 .in_fade.sh-morebtn.active {
    opacity: 1;
    top: 0; }
    .indexPage .item2 .in_fade.sh-morebtn.active::before {
      width: 100%; }
    .indexPage .item2 .in_fade.sh-morebtn.active span {
      color: #fff; }

.indexPage .item3 {
  margin-top: 135px;
  margin-bottom: 180px; }
  @media (max-width: 767px) {
    .indexPage .item3 {
      margin-top: 60px;
      margin-bottom: 40px; } }
  .indexPage .item3 .it3-bx {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    @media (max-width: 991px) {
      .indexPage .item3 .it3-bx {
        display: block;
        position: relative;
        padding-top: 95px; } }
    .indexPage .item3 .it3-bx .t {
      order: 2;
      width: 53.5%; }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .t {
          width: 100%; } }
    .indexPage .item3 .it3-bx .b {
      order: 1;
      width: 43%;
      margin-bottom: 15px; }
      @media (max-width: 991px) {
        .indexPage .item3 .it3-bx .b {
          width: 100%;
          margin-top: 40px; } }
      .indexPage .item3 .it3-bx .b .ti-bx {
        margin-bottom: 50px; }
        @media (max-width: 991px) {
          .indexPage .item3 .it3-bx .b .ti-bx {
            position: absolute;
            top: 0;
            text-align: center;
            width: 100%; } }
        .indexPage .item3 .it3-bx .b .ti-bx .en-ti {
          font-size: 3.75rem;
          line-height: 1;
          letter-spacing: 0px;
          font-weight: 300;
          color: #244981;
          margin-bottom: 22px; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .b .ti-bx .en-ti {
              font-size: 2.5rem;
              margin-bottom: 10px; } }
        .indexPage .item3 .it3-bx .b .ti-bx .ti {
          font-size: 1.375rem;
          line-height: 1;
          letter-spacing: 0px;
          font-weight: 700;
          color: #ea9a03;
          position: relative; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .b .ti-bx .ti {
              font-size: 1.125rem; } }
      .indexPage .item3 .it3-bx .b .sh-des {
        margin-bottom: 75px; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .b .sh-des {
            text-align: left;
            margin-bottom: 30px; } }
      .indexPage .item3 .it3-bx .b .link-bx {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .b .link-bx {
            display: flex;
            padding: 0 7px; } }
      .indexPage .item3 .it3-bx .b .sh-morebtn {
        width: 269px;
        height: 96px;
        padding-top: 18px;
        margin: 0; }
        .indexPage .item3 .it3-bx .b .sh-morebtn:first-child {
          margin-right: 20px; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .b .sh-morebtn:first-child {
              margin-right: 10px; } }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .b .sh-morebtn::before, .indexPage .item3 .it3-bx .b .sh-morebtn::after {
            transform: skewX(-20deg); } }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .b .sh-morebtn {
            height: 75px;
            padding-top: 12px; } }
        @media (min-width: 768px) {
          .indexPage .item3 .it3-bx .b .sh-morebtn:hover i {
            color: #244981; } }
        .indexPage .item3 .it3-bx .b .sh-morebtn i {
          font-size: 1.375rem;
          line-height: 1.45;
          letter-spacing: 0px;
          font-weight: 400;
          color: #ea9a03;
          position: relative;
          z-index: 5;
          transition: .4s ease; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .b .sh-morebtn i {
              font-size: 1.125rem;
              letter-spacing: 0; } }
        .indexPage .item3 .it3-bx .b .sh-morebtn span {
          line-height: 1.5;
          transition: .4s ease; }
          @media (max-width: 767px) {
            .indexPage .item3 .it3-bx .b .sh-morebtn span {
              letter-spacing: 0; } }
      .indexPage .item3 .it3-bx .b .in_fade.sh-morebtn {
        opacity: 1;
        top: 0; }
        .indexPage .item3 .it3-bx .b .in_fade.sh-morebtn::before {
          width: 0;
          transition: .6s ease; }
      .indexPage .item3 .it3-bx .b .in_fade.sh-morebtn.active::before {
        width: 100%; }

.aboutPage .item1 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .aboutPage .item1 {
      margin-top: 30px; } }
  .aboutPage .item1 .it1-bx {
    display: flex;
    justify-content: space-between;
    margin-top: 70px; }
    @media (max-width: 991px) {
      .aboutPage .item1 .it1-bx {
        display: block;
        margin-top: 25px; } }
    .aboutPage .item1 .it1-bx .l {
      width: 54.3%; }
      @media (max-width: 991px) {
        .aboutPage .item1 .it1-bx .l {
          width: 100%;
          margin-bottom: 23px; } }
      .aboutPage .item1 .it1-bx .l .bgcover {
        width: 100%;
        height: 420px; }
        @media (max-width: 991px) {
          .aboutPage .item1 .it1-bx .l .bgcover {
            height: 0;
            padding-bottom: 66.66666%;
            background-size: cover !important; } }
    .aboutPage .item1 .it1-bx .r {
      width: 40.2%;
      margin-top: -5px;
      margin-bottom: 83px; }
      @media (max-width: 991px) {
        .aboutPage .item1 .it1-bx .r {
          width: 100%;
          margin-top: 0;
          margin-bottom: 30px; } }
      .aboutPage .item1 .it1-bx .r .ti {
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 700;
        color: #ea9a03;
        margin-bottom: 25px; }
        @media (max-width: 767px) {
          .aboutPage .item1 .it1-bx .r .ti {
            font-size: 1.125rem;
            margin-bottom: 15px; } }

.aboutPage .item2 {
  padding-top: 100px;
  position: relative;
  background: url(../images/about/c_bg.jpg) no-repeat center center/1920px; }
  @media (max-width: 767px) {
    .aboutPage .item2 {
      padding-top: 50px;
      background: url(../images/about/c_bg_mb.jpg) no-repeat center center/cover;
      background-position-y: -30px; } }
  .aboutPage .item2::before {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 30px);
    content: '';
    z-index: -1; }
  .aboutPage .item2 .it2-bx {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; }
    @media (max-width: 767px) {
      .aboutPage .item2 .it2-bx {
        display: block;
        margin-bottom: 75px; } }
    .aboutPage .item2 .it2-bx .l {
      width: 35%; }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .l {
          width: 49%; } }
      @media (max-width: 767px) {
        .aboutPage .item2 .it2-bx .l {
          width: 100%; } }
      .aboutPage .item2 .it2-bx .l .bl-ti {
        font-size: 1.875rem;
        line-height: 1.6;
        letter-spacing: 0px;
        font-weight: 700;
        color: #244981;
        position: relative; }
        @media (max-width: 767px) {
          .aboutPage .item2 .it2-bx .l .bl-ti {
            font-size: 1.25rem;
            margin-bottom: 65px; } }
        .aboutPage .item2 .it2-bx .l .bl-ti::before {
          position: absolute;
          content: '';
          bottom: -40px;
          left: 0;
          width: 208px;
          height: 5px;
          background-color: #ea9a03; }
          @media (max-width: 767px) {
            .aboutPage .item2 .it2-bx .l .bl-ti::before {
              bottom: -20px;
              width: 140px;
              height: 2px; } }
    .aboutPage .item2 .it2-bx .r {
      width: 63.1%; }
      @media (max-width: 767px) {
        .aboutPage .item2 .it2-bx .r {
          width: 100%; } }
      .aboutPage .item2 .it2-bx .r .it2-list {
        display: flex;
        justify-content: space-between; }
        .aboutPage .item2 .it2-bx .r .it2-list li {
          width: 27.5%; }
          @media (max-width: 767px) {
            .aboutPage .item2 .it2-bx .r .it2-list li {
              width: 29%; } }
          .aboutPage .item2 .it2-bx .r .it2-list li .des {
            text-align: center;
            font-size: 1.375rem;
            line-height: 1;
            letter-spacing: 0px;
            font-weight: 400;
            color: #244981;
            margin-top: 30px; }
            @media (max-width: 767px) {
              .aboutPage .item2 .it2-bx .r .it2-list li .des {
                font-size: 0.9375rem;
                margin-top: 15px; } }
  .aboutPage .item2 .sh-morebtn {
    max-width: 523px;
    width: 100%;
    height: 96px;
    margin: 0; }
    @media (max-width: 767px) {
      .aboutPage .item2 .sh-morebtn {
        max-width: 280px;
        height: 60px;
        margin-left: 10px; } }
    .aboutPage .item2 .sh-morebtn::after {
      display: none; }
    .aboutPage .item2 .sh-morebtn span {
      line-height: 4.4;
      font-size: 1.5rem; }
      @media (max-width: 767px) {
        .aboutPage .item2 .sh-morebtn span {
          font-size: 1rem;
          line-height: 4.1; } }
    .aboutPage .item2 .sh-morebtn:hover span {
      color: #fff !important; }

.aboutPage .item3 {
  margin-top: 65px;
  margin-bottom: 120px; }
  @media (max-width: 991px) {
    .aboutPage .item3 {
      margin-top: 40px;
      margin-bottom: 40px; } }
  .aboutPage .item3 .it3-bx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .aboutPage .item3 .it3-bx {
        display: block; } }
    .aboutPage .item3 .it3-bx .t {
      order: 2;
      width: 47%; }
      @media (max-width: 991px) {
        .aboutPage .item3 .it3-bx .t {
          width: 100%; } }
      .aboutPage .item3 .it3-bx .t .bgcover {
        width: 100%;
        background-size: cover !important;
        height: 330px; }
        @media (max-width: 991px) {
          .aboutPage .item3 .it3-bx .t .bgcover {
            padding-bottom: 60%;
            height: 0;
            margin-bottom: 30px; } }
    .aboutPage .item3 .it3-bx .b {
      order: 1;
      width: 47.5%;
      position: relative;
      margin-top: -5px; }
      @media (max-width: 991px) {
        .aboutPage .item3 .it3-bx .b {
          width: 100%;
          margin-top: 0; } }
  .aboutPage .item3 .ti3-list {
    margin-top: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (max-width: 991px) {
      .aboutPage .item3 .ti3-list {
        margin-top: 35px; } }
    .aboutPage .item3 .ti3-list li {
      width: 20%;
      text-align: center; }
      @media (max-width: 1199px) {
        .aboutPage .item3 .ti3-list li {
          width: 30%; } }
      @media (max-width: 991px) {
        .aboutPage .item3 .ti3-list li {
          width: 33.33333%; } }
      @media (max-width: 767px) {
        .aboutPage .item3 .ti3-list li {
          margin-bottom: 30px; }
          .aboutPage .item3 .ti3-list li .img-bx {
            width: 45px;
            margin: 0 auto; }
          .aboutPage .item3 .ti3-list li:nth-child(4) {
            width: 45%; }
          .aboutPage .item3 .ti3-list li:nth-child(5) {
            width: 45%; } }
      .aboutPage .item3 .ti3-list li .des {
        margin-top: 17px;
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 400;
        color: #333; }
        @media (max-width: 991px) {
          .aboutPage .item3 .ti3-list li .des {
            font-size: 0.9375rem;
            margin-top: 10px; } }

@media (max-width: 767px) {
  .productPage .sh-insdtit .ti:before {
    right: -35px; } }

.productPage .item1 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .productPage .item1 {
      margin-top: 50px; } }
  .productPage .item1 .it1-list {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 991px) and (min-width: 768px) {
      .productPage .item1 .it1-list {
        justify-content: space-between; } }
    @media (max-width: 767px) {
      .productPage .item1 .it1-list {
        display: block;
        margin-top: 30px; } }
    .productPage .item1 .it1-list li {
      width: 31%;
      text-align: center;
      margin-bottom: 90px; }
      @media (min-width: 991px) {
        .productPage .item1 .it1-list li:not(:nth-child(3n)) {
          margin-right: 3.5%; } }
      @media (max-width: 991px) and (min-width: 768px) {
        .productPage .item1 .it1-list li {
          width: 45%; } }
      @media (max-width: 767px) {
        .productPage .item1 .it1-list li {
          width: 100%;
          margin-bottom: 45px; } }
      @media (min-width: 768px) {
        .productPage .item1 .it1-list li:nth-child(3n) {
          transition-delay: .6s; }
        .productPage .item1 .it1-list li:nth-child(3n-1) {
          transition-delay: .3s; }
        .productPage .item1 .it1-list li:hover .img-bx {
          opacity: .5; }
        .productPage .item1 .it1-list li:hover .des-bx .des {
          color: #244981; }
        .productPage .item1 .it1-list li:hover .more-btn::after {
          width: 100%; } }
      .productPage .item1 .it1-list li .img-bx {
        width: 85%;
        margin: 0 auto;
        transition: .6s ease; }
        @media (max-width: 767px) {
          .productPage .item1 .it1-list li .img-bx {
            width: 65%; } }
      .productPage .item1 .it1-list li .des-bx {
        margin-top: 5px; }
        @media (max-width: 767px) {
          .productPage .item1 .it1-list li .des-bx {
            margin-top: 10px; } }
        .productPage .item1 .it1-list li .des-bx .ti {
          font-size: 1.375rem;
          line-height: 1.6;
          letter-spacing: 0px;
          font-weight: 400;
          color: #244981; }
          @media (max-width: 767px) {
            .productPage .item1 .it1-list li .des-bx .ti {
              font-size: 1.125rem; } }
        .productPage .item1 .it1-list li .des-bx .des {
          font-size: 1.125rem;
          line-height: 1.6;
          letter-spacing: 0px;
          font-weight: 400;
          color: #333333;
          transition: .4s ease; }
          @media (max-width: 767px) {
            .productPage .item1 .it1-list li .des-bx .des {
              font-size: 0.9375rem; } }
      .productPage .item1 .it1-list li .more-btn {
        width: 120px;
        height: 40px;
        font-size: 1.125rem;
        line-height: 2.2;
        letter-spacing: 0px;
        font-weight: 300;
        color: #fff;
        margin: 0 auto;
        margin-top: 25px;
        transition: .4s ease;
        position: relative; }
        @media (max-width: 767px) {
          .productPage .item1 .it1-list li .more-btn {
            width: 105px;
            height: 35px;
            font-size: 0.9375rem;
            margin-top: 12px; } }
        .productPage .item1 .it1-list li .more-btn span {
          position: relative;
          z-index: 5; }
        .productPage .item1 .it1-list li .more-btn::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #244981;
          z-index: 3; }
        .productPage .item1 .it1-list li .more-btn::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          transition: .4s ease;
          z-index: 4;
          background-color: #ea9a03; }

.productdetailPage .item1 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .productdetailPage .item1 {
      margin-top: 25px; } }
  .productdetailPage .item1 .sh-insdtit .ti:before {
    right: -65px; }
    @media (max-width: 767px) {
      .productdetailPage .item1 .sh-insdtit .ti:before {
        right: -10px; } }
  .productdetailPage .item1 .sh-insdtit .des {
    font-size: 1.375rem;
    line-height: 1.7;
    letter-spacing: 0px;
    font-weight: 400;
    color: #244981;
    margin-top: 7px; }
    @media (max-width: 767px) {
      .productdetailPage .item1 .sh-insdtit .des {
        font-size: 0.9375rem; } }
  .productdetailPage .item1 .pro-img {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .productdetailPage .item1 .pro-img {
        width: 65%;
        margin: 0 auto;
        margin-top: 25px; } }
  .productdetailPage .item1 .editor_Content {
    max-width: 1037px;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    margin-bottom: 80px; }
    @media (max-width: 767px) {
      .productdetailPage .item1 .editor_Content {
        margin-top: 15px;
        margin-bottom: 40px;
        padding: 0; } }
  .productdetailPage .item1 .sh-morebtn {
    width: 285px;
    height: 70px;
    margin-bottom: 90px; }
    @media (max-width: 767px) {
      .productdetailPage .item1 .sh-morebtn {
        width: 195px;
        height: 45px;
        margin-bottom: 75px; } }
    @media (max-width: 767px) {
      .productdetailPage .item1 .sh-morebtn span {
        line-height: 2.6; } }

@media (min-width: 1199px) {
  .casePage .container {
    max-width: 1038px; } }

.casePage .item1 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .casePage .item1 {
      margin-top: 50px; } }
  .casePage .item1 .sh-insdtit {
    margin-bottom: 50px; }
  .casePage .item1 .editor_Content {
    margin-bottom: 105px; }
    @media (max-width: 767px) {
      .casePage .item1 .editor_Content {
        margin-bottom: 50px; } }

.contactPage .sh-insdtit .ti:before {
  right: 65px; }
  @media (max-width: 767px) {
    .contactPage .sh-insdtit .ti:before {
      right: 25px;
      top: 0; } }

@media (max-width: 767px) {
  .contactPage .sh-insdtit .ti {
    font-size: 0.9375rem;
    line-height: 1.7; } }

.contactPage .sh-insdtit {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .contactPage .sh-insdtit {
      margin-top: 40px; } }

.contactPage .item1 {
  margin-top: 80px;
  position: relative;
  margin-bottom: 120px;
  background: url(../images/contact/b_bg.jpg) no-repeat center center/1370px;
  background-position-y: 30px; }
  @media (max-width: 1370px) {
    .contactPage .item1 {
      overflow: hidden; } }
  .contactPage .item1::before {
    position: absolute;
    content: '';
    width: 1370px;
    top: 30px;
    height: calc(100% - 85px);
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
  @media (max-width: 767px) {
    .contactPage .item1 {
      margin-top: 35px;
      margin-bottom: 60px; } }
  @media (max-width: 500px) {
    .contactPage .item1 {
      background: url(../images/contact/b_bg_mb.jpg) no-repeat center center/cover;
      background-position-y: 200px;
      background-size: contain; } }
  .contactPage .item1 .it1-bx {
    position: relative; }
    @media (max-width: 767px) {
      .contactPage .item1 .it1-bx {
        margin-top: 40px; } }
  .contactPage .item1 .flex-bx {
    margin-top: 45px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .contactPage .item1 .flex-bx {
        display: block; } }
    @media (max-width: 767px) {
      .contactPage .item1 .flex-bx {
        margin-top: 25px; } }
    .contactPage .item1 .flex-bx .l {
      width: 37%; }
      @media (max-width: 991px) {
        .contactPage .item1 .flex-bx .l {
          width: 65%;
          margin: 0 auto; } }
      .contactPage .item1 .flex-bx .l .bgcover {
        padding-bottom: 450px;
        background-size: cover !important; }
        @media (max-width: 767px) {
          .contactPage .item1 .flex-bx .l .bgcover {
            padding-bottom: 225px; } }
    .contactPage .item1 .flex-bx .r {
      width: 58%; }
      @media (max-width: 991px) {
        .contactPage .item1 .flex-bx .r {
          width: 100%; } }
      .contactPage .item1 .flex-bx .r .con-ti {
        max-width: 337px; }
        @media (max-width: 991px) {
          .contactPage .item1 .flex-bx .r .con-ti {
            max-width: 83%;
            margin: 0 auto;
            margin-top: 35px; } }
      .contactPage .item1 .flex-bx .r .conlist {
        margin-top: 50px;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap; }
        @media (max-width: 767px) {
          .contactPage .item1 .flex-bx .r .conlist {
            display: block;
            margin-top: 30px;
            margin-bottom: 50px; } }
        .contactPage .item1 .flex-bx .r .conlist li:nth-child(1), .contactPage .item1 .flex-bx .r .conlist li:nth-child(3) {
          width: 60%; }
          @media (max-width: 767px) {
            .contactPage .item1 .flex-bx .r .conlist li:nth-child(1), .contactPage .item1 .flex-bx .r .conlist li:nth-child(3) {
              width: 100%; } }
        .contactPage .item1 .flex-bx .r .conlist li:nth-child(2) {
          width: 40%; }
          @media (max-width: 767px) {
            .contactPage .item1 .flex-bx .r .conlist li:nth-child(2) {
              width: 100%; } }
        .contactPage .item1 .flex-bx .r .conlist li {
          margin-bottom: 30px; }
          @media (max-width: 767px) {
            .contactPage .item1 .flex-bx .r .conlist li {
              text-align: center;
              margin-bottom: 25px; } }
          .contactPage .item1 .flex-bx .r .conlist li .des-ti {
            font-size: 1.375rem;
            line-height: 1.64;
            letter-spacing: 0px;
            font-weight: 700;
            color: #244981; }
            @media (max-width: 767px) {
              .contactPage .item1 .flex-bx .r .conlist li .des-ti {
                font-size: 0.9375rem; } }
          .contactPage .item1 .flex-bx .r .conlist li a, .contactPage .item1 .flex-bx .r .conlist li .adr {
            font-size: 1.375rem;
            line-height: 1.64;
            letter-spacing: 0px;
            font-weight: 400;
            color: #000000; }
            @media (max-width: 767px) {
              .contactPage .item1 .flex-bx .r .conlist li a, .contactPage .item1 .flex-bx .r .conlist li .adr {
                font-size: 0.9375rem; } }
          .contactPage .item1 .flex-bx .r .conlist li .adr img {
            margin-left: 6px;
            position: relative;
            top: -2px; }
      .contactPage .item1 .flex-bx .r .link-bx {
        display: flex;
        width: calc(100% + 16px);
        margin-left: -8px; }
        @media (max-width: 1199px) {
          .contactPage .item1 .flex-bx .r .link-bx {
            flex-wrap: wrap; } }
        .contactPage .item1 .flex-bx .r .link-bx a {
          display: block;
          width: 25%;
          height: 85px;
          font-size: 1.25rem;
          line-height: 1.4;
          letter-spacing: 0px;
          font-weight: 700;
          color: #ea9a03;
          position: relative;
          text-align: center;
          padding-top: 15px;
          margin: 0 8px;
          padding-left: 3px;
          padding-right: 10px; }
          @media (max-width: 1199px) {
            .contactPage .item1 .flex-bx .r .link-bx a {
              margin-bottom: 13px;
              width: 32%; } }
          @media (max-width: 991px) {
            .contactPage .item1 .flex-bx .r .link-bx a {
              width: 45%; } }
          @media (max-width: 767px) {
            .contactPage .item1 .flex-bx .r .link-bx a {
              font-size: 1.125rem;
              height: 70px;
              padding-top: 10px; } }
          @media (max-width: 350px) {
            .contactPage .item1 .flex-bx .r .link-bx a {
              padding-left: 2px;
              padding-right: 2px;
              width: 44%; } }
          .contactPage .item1 .flex-bx .r .link-bx a::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #244981;
            z-index: 3;
            transform: skewX(-14deg); }
            @media (max-width: 767px) {
              .contactPage .item1 .flex-bx .r .link-bx a::before {
                transform: skewX(-14deg); } }
          .contactPage .item1 .flex-bx .r .link-bx a::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            transition: .4s ease;
            z-index: 4;
            background-color: #ea9a03;
            transform: skewX(-25deg); }
          .contactPage .item1 .flex-bx .r .link-bx a span {
            position: relative;
            z-index: 5; }
          .contactPage .item1 .flex-bx .r .link-bx a i {
            font-weight: 400;
            color: #fff;
            display: block;
            position: relative;
            z-index: 5; }

@media (max-width: 767px) {
  .pestintrPage .sh-inbanner .tx-bx .tw-ti {
    margin-left: 0;
    line-height: 1.3; } }

@media (max-width: 767px) {
  .pestintrPage .sh-inbanner .tx-bx .tw-ti {
    margin-left: 0; } }

@media (min-width: 1199px) {
  .pestintrPage .container {
    max-width: 1038px; } }

.pestintrPage .item1 {
  margin-top: 85px; }
  @media (max-width: 767px) {
    .pestintrPage .item1 {
      margin-top: 40px; } }
  .pestintrPage .item1 .peint-ti {
    min-width: 310px;
    height: 60px;
    display: inline-block;
    position: relative;
    text-align: center;
    padding: 0 35px;
    margin-bottom: 45px;
    margin-left: -45px; }
    @media (max-width: 767px) {
      .pestintrPage .item1 .peint-ti {
        min-width: 195px;
        height: 45px;
        margin-left: 15px;
        margin-bottom: 30px; } }
    .pestintrPage .item1 .peint-ti::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background-color: #244981;
      transform: skewX(-30deg); }
    .pestintrPage .item1 .peint-ti::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #ea9a03;
      transform: skewX(-30deg);
      z-index: 4;
      transition: .4s ease; }
    .pestintrPage .item1 .peint-ti .t {
      font-size: 1.875rem;
      line-height: 1.9;
      letter-spacing: 0px;
      font-weight: 700;
      color: #fff;
      position: relative;
      z-index: 5; }
      @media (max-width: 767px) {
        .pestintrPage .item1 .peint-ti .t {
          line-height: 2.3;
          font-size: 1.25rem; } }
      .pestintrPage .item1 .peint-ti .t .e {
        font-size: 1.125rem;
        font-weight: 300;
        font-weight: "Inria Sans";
        text-transform: uppercase;
        margin-left: 15px; }
        @media (max-width: 767px) {
          .pestintrPage .item1 .peint-ti .t .e {
            font-size: 0.75rem; } }
  .pestintrPage .item1 .blbg-ti {
    font-size: 1.375rem;
    line-height: 1;
    letter-spacing: 0px;
    font-weight: 700;
    color: #244981;
    text-align: center;
    background-color: #eff6ff;
    padding: 17px 0; }
    @media (max-width: 767px) {
      .pestintrPage .item1 .blbg-ti {
        font-size: 1.125rem;
        padding: 17px 15px; } }
  .pestintrPage .item1 .editor_Content {
    margin-top: 45px;
    margin-bottom: 100px; }
    @media (max-width: 767px) {
      .pestintrPage .item1 .editor_Content {
        margin-top: 30px;
        margin-bottom: 50px; } }
  .pestintrPage .item1 .sh-morebtn {
    width: 285px;
    height: 70px;
    margin-bottom: 90px; }
    @media (max-width: 767px) {
      .pestintrPage .item1 .sh-morebtn {
        width: 195px;
        height: 45px;
        margin-bottom: 75px; } }
    @media (max-width: 767px) {
      .pestintrPage .item1 .sh-morebtn span {
        line-height: 2.6; } }

@media (max-width: 767px) {
  .pestPage .sh-inbanner .tx-bx .tw-ti {
    margin-left: 0;
    line-height: 1.3; } }

.pestPage .sh-insdtit .ti:before {
  right: -70px; }
  @media (max-width: 767px) {
    .pestPage .sh-insdtit .ti:before {
      right: -30px; } }

.pestPage .item1 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .pestPage .item1 {
      margin-top: 40px; } }
  .pestPage .item1 .it1-bx {
    height: auto;
    margin-top: 75px;
    margin-bottom: 100px;
    border-top: 1px solid #dfdcdc;
    position: relative;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 991px) {
      .pestPage .item1 .it1-bx {
        height: auto; } }
    @media (max-width: 767px) {
      .pestPage .item1 .it1-bx {
        margin-top: 35px;
        margin-bottom: 50px; } }
    .pestPage .item1 .it1-bx::before {
      position: absolute;
      bottom: 0;
      content: '';
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dfdcdc;
      z-index: 10; }
    .pestPage .item1 .it1-bx .l {
      min-width: 295px;
      max-width: 295px; }
      @media (max-width: 767px) {
        .pestPage .item1 .it1-bx .l {
          min-width: 147px;
          max-width: 147px; } }
      .pestPage .item1 .it1-bx .l .m-list li.active a {
        color: #fff; }
        .pestPage .item1 .it1-bx .l .m-list li.active a span::after {
          height: 0; }
        .pestPage .item1 .it1-bx .l .m-list li.active a::after {
          width: 100%; }
      .pestPage .item1 .it1-bx .l .m-list li a {
        height: 70px;
        display: block;
        font-size: 1.375rem;
        line-height: 3.2;
        letter-spacing: 0px;
        font-weight: 700;
        color: #244981;
        text-align: center;
        transition: .4s ease;
        position: relative;
        overflow: hidden; }
        @media (max-width: 767px) {
          .pestPage .item1 .it1-bx .l .m-list li a {
            font-size: 0.875rem;
            height: 35px;
            line-height: 2.5; } }
        .pestPage .item1 .it1-bx .l .m-list li a span {
          position: relative;
          z-index: 5; }
        .pestPage .item1 .it1-bx .l .m-list li a::before {
          position: absolute;
          content: '';
          right: 0;
          height: 0;
          width: 0;
          border-bottom: 71px solid #fff;
          border-right: 0 dashed transparent;
          border-left: 50px dashed transparent;
          z-index: 6; }
          @media (max-width: 767px) {
            .pestPage .item1 .it1-bx .l .m-list li a::before {
              border-bottom: 35px solid #fff;
              border-right: 0 dashed transparent;
              border-left: 38px dashed transparent; } }
        .pestPage .item1 .it1-bx .l .m-list li a::after {
          position: absolute;
          content: '';
          top: 0;
          left: -25px;
          width: 0;
          height: 100%;
          background-color: #244981;
          transition: .6s ease;
          transform: skewX(-35deg);
          z-index: 4; }
        @media (min-width: 768px) {
          .pestPage .item1 .it1-bx .l .m-list li a:hover {
            color: #fff; }
            .pestPage .item1 .it1-bx .l .m-list li a:hover span::after {
              height: 0; }
            .pestPage .item1 .it1-bx .l .m-list li a:hover::after {
              width: 100%; } }
        .pestPage .item1 .it1-bx .l .m-list li a span {
          position: relative;
          padding-right: 50px;
          left: -10px; }
          @media (max-width: 767px) {
            .pestPage .item1 .it1-bx .l .m-list li a span {
              padding-right: 25px; } }
          .pestPage .item1 .it1-bx .l .m-list li a span::before {
            position: absolute;
            content: '';
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 1px;
            background-color: #dddddd; }
          .pestPage .item1 .it1-bx .l .m-list li a span::after {
            position: absolute;
            content: '';
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background-color: #dddddd; }
      .pestPage .item1 .it1-bx .l .m-list li:not(:last-child) {
        position: relative; }
        .pestPage .item1 .it1-bx .l .m-list li:not(:last-child)::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #dfdcdc; }
    .pestPage .item1 .it1-bx .r {
      width: calc(100% - 147px);
      position: relative; }
      .pestPage .item1 .it1-bx .r .p-list {
        width: 90%;
        margin: 0 auto; }
        @media (max-width: 991px) {
          .pestPage .item1 .it1-bx .r .p-list {
            margin-top: 15px; } }
        .pestPage .item1 .it1-bx .r .p-list li.active {
          opacity: 1;
          pointer-events: all;
          display: flex; }
          @media (max-width: 991px) {
            .pestPage .item1 .it1-bx .r .p-list li.active {
              display: block; } }
        .pestPage .item1 .it1-bx .r .p-list li {
          position: relative;
          margin-top: 25px;
          margin-bottom: 25px;
          left: 0;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          opacity: 0;
          pointer-events: none;
          display: none; }
          @media (max-width: 991px) {
            .pestPage .item1 .it1-bx .r .p-list li {
              margin-top: 12px;
              margin-bottom: 0px; } }
          .pestPage .item1 .it1-bx .r .p-list li .it {
            width: 38%;
            display: flex;
            margin: 0;
            margin-left: 10%;
            margin-bottom: 35px; }
            @media (max-width: 991px) {
              .pestPage .item1 .it1-bx .r .p-list li .it {
                margin-bottom: 20px;
                width: 100%; } }
            .pestPage .item1 .it1-bx .r .p-list li .it:last-child {
              margin-bottom: 0; }
              @media (max-width: 991px) {
                .pestPage .item1 .it1-bx .r .p-list li .it:last-child {
                  margin-bottom: 20px; } }
            .pestPage .item1 .it1-bx .r .p-list li .it:nth-last-child(2) {
              margin-bottom: 0; }
              @media (max-width: 991px) {
                .pestPage .item1 .it1-bx .r .p-list li .it:nth-last-child(2) {
                  margin-bottom: 30px; } }
            .pestPage .item1 .it1-bx .r .p-list li .it .img-bx {
              margin-right: 15px; }
              @media (max-width: 767px) {
                .pestPage .item1 .it1-bx .r .p-list li .it .img-bx {
                  width: 35%; } }
            .pestPage .item1 .it1-bx .r .p-list li .it .des-bx {
              max-width: 135px;
              padding-top: 12px;
              width: 135px; }
              @media (max-width: 767px) {
                .pestPage .item1 .it1-bx .r .p-list li .it .des-bx {
                  max-width: 75px;
                  width: 75px;
                  padding-top: 2px; } }
              .pestPage .item1 .it1-bx .r .p-list li .it .des-bx .ti {
                font-size: 1.375rem;
                line-height: 1.4;
                letter-spacing: 0px;
                font-weight: 400;
                color: #244981;
                margin-bottom: 4px; }
                @media (max-width: 767px) {
                  .pestPage .item1 .it1-bx .r .p-list li .it .des-bx .ti {
                    font-size: 0.875rem; } }
              .pestPage .item1 .it1-bx .r .p-list li .it .des-bx .en-ti {
                font-size: 1.125rem;
                line-height: 1.4;
                letter-spacing: 0px;
                font-weight: 300;
                color: #777; }
                @media (max-width: 767px) {
                  .pestPage .item1 .it1-bx .r .p-list li .it .des-bx .en-ti {
                    font-size: 0.75rem; } }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line1 {
  width: 49.5%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line2 {
  width: 90.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line3 {
  width: 57.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line4 .l1 {
  width: 24.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line4 .l2 {
  width: 16.5%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line5 {
  width: 57.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line6 {
  width: 66%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line7 {
  width: 49.5%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line8 .l1 {
  width: 24.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line8 .l2 {
  width: 24.75%; }

.pestPage .item2.in_fade.active .it2-bx .r .line-bx .line9 {
  width: 57.75%; }

.pestPage .item2 .blbg-ti {
  width: 100%;
  padding: 19px 0;
  text-align: center;
  font-size: 1.875rem;
  line-height: 1.3;
  letter-spacing: 0px;
  font-weight: 700;
  color: #244981;
  background-color: #eff6ff;
  margin-bottom: 55px; }
  @media (max-width: 767px) {
    .pestPage .item2 .blbg-ti {
      font-size: 1.25rem;
      padding: 12px 0;
      margin-bottom: 30px; } }

.pestPage .item2 .it2-obx {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%; }

.pestPage .item2 .it2-bx {
  display: flex;
  height: 503px; }
  @media (max-width: 1199px) {
    .pestPage .item2 .it2-bx {
      width: 928px;
      position: relative; } }
  @media (max-width: 991px) {
    .pestPage .item2 .it2-bx {
      height: 370px; } }
  .pestPage .item2 .it2-bx .l {
    width: 125px;
    padding-top: 90px; }
    @media (max-width: 991px) {
      .pestPage .item2 .it2-bx .l {
        width: 85px;
        padding-top: 67px; } }
    .pestPage .item2 .it2-bx .l ul li {
      font-size: 1.375rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 400;
      color: #244981; }
      @media (max-width: 991px) {
        .pestPage .item2 .it2-bx .l ul li {
          font-size: 0.875rem; } }
      .pestPage .item2 .it2-bx .l ul li:not(:last-child) {
        margin-bottom: 25px; }
        @media (max-width: 991px) {
          .pestPage .item2 .it2-bx .l ul li:not(:last-child) {
            margin-bottom: 17px; } }
  .pestPage .item2 .it2-bx .r {
    width: calc(100% - 125px); }
    @media (max-width: 991px) {
      .pestPage .item2 .it2-bx .r {
        margin-left: -50px;
        width: calc(90% - 35px); } }
    .pestPage .item2 .it2-bx .r ul {
      display: flex; }
      .pestPage .item2 .it2-bx .r ul li {
        width: 8.25%;
        text-align: right;
        font-size: 1.25rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 400;
        color: #244981;
        position: relative; }
        @media (max-width: 991px) {
          .pestPage .item2 .it2-bx .r ul li {
            font-size: 1rem; } }
        .pestPage .item2 .it2-bx .r ul li::before {
          position: absolute;
          content: '';
          right: 14px;
          top: 28px;
          height: 475px;
          width: 1px;
          background-color: #dfdcdc;
          z-index: -1; }
    .pestPage .item2 .it2-bx .r .line-bx {
      margin-top: 74px; }
      @media (max-width: 991px) {
        .pestPage .item2 .it2-bx .r .line-bx {
          margin-top: 53px; } }
      .pestPage .item2 .it2-bx .r .line-bx .line, .pestPage .item2 .it2-bx .r .line-bx .l1, .pestPage .item2 .it2-bx .r .line-bx .l2 {
        transition: 1s ease;
        height: 15px; }
        @media (max-width: 991px) {
          .pestPage .item2 .it2-bx .r .line-bx .line, .pestPage .item2 .it2-bx .r .line-bx .l1, .pestPage .item2 .it2-bx .r .line-bx .l2 {
            height: 11px; } }
      .pestPage .item2 .it2-bx .r .line-bx .line1 {
        margin-left: calc(33% - 14px);
        background-color: #ea9a03;
        width: 0; }
      .pestPage .item2 .it2-bx .r .line-bx .line2 {
        margin-left: calc(8.25% - 14px);
        background-color: #00a29a;
        width: 0;
        transition-delay: .3s; }
      .pestPage .item2 .it2-bx .r .line-bx .line3 {
        margin-left: calc(24.75% - 14px);
        background-color: #cacc43;
        width: 0;
        transition-delay: .6s; }
      .pestPage .item2 .it2-bx .r .line-bx .line4 {
        display: flex; }
        .pestPage .item2 .it2-bx .r .line-bx .line4 .l1 {
          margin-left: calc(33% - 14px);
          background-color: #7a6a56;
          width: 0;
          transition-delay: .9s; }
        .pestPage .item2 .it2-bx .r .line-bx .line4 .l2 {
          background-color: #7a6a56;
          margin-left: 16.5%;
          width: 0;
          transition-delay: .9s; }
      .pestPage .item2 .it2-bx .r .line-bx .line5 {
        margin-left: calc(24.75% - 14px);
        background-color: #ee7743;
        width: 0;
        transition-delay: 1.2s; }
      .pestPage .item2 .it2-bx .r .line-bx .line6 {
        margin-left: calc(24.75% - 14px);
        width: 0;
        background-color: #601986;
        transition-delay: 1.5s; }
      .pestPage .item2 .it2-bx .r .line-bx .line7 {
        margin-left: calc(33% - 14px);
        width: 0;
        background-color: #244981;
        transition-delay: 1.8s; }
      .pestPage .item2 .it2-bx .r .line-bx .line8 {
        display: flex; }
        .pestPage .item2 .it2-bx .r .line-bx .line8 .l1 {
          margin-left: calc(8.25% - 14px);
          width: 0;
          background-color: #9fa0a0;
          transition-delay: 2.1s; }
        .pestPage .item2 .it2-bx .r .line-bx .line8 .l2 {
          background-color: #9fa0a0;
          width: 0;
          margin-left: 41.25%;
          transition-delay: 2.1s; }
      .pestPage .item2 .it2-bx .r .line-bx .line9 {
        margin-left: calc(24.75% - 14px);
        width: 0;
        background-color: #a40b5d;
        transition-delay: 2.4s; }
      .pestPage .item2 .it2-bx .r .line-bx .line:not(:last-child) {
        margin-bottom: 32px; }
        @media (max-width: 991px) {
          .pestPage .item2 .it2-bx .r .line-bx .line:not(:last-child) {
            margin-bottom: 20px; } }

.pestPage .item2 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #aaaaaa;
  border-radius: 0;
  height: 3px; }

.pestPage .item2 .mCSB_scrollTools .mCSB_draggerRail {
  background-color: #eeeeee;
  height: 3px; }

.pestPage .item2 .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  opacity: 1;
  background: url(../images/mCSB_buttons.png) no-repeat center center/5px;
  top: -2px; }

.pestPage .item2 .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  opacity: 1;
  top: -2px;
  transform: rotate(180deg);
  background: url(../images/mCSB_buttons.png) no-repeat center center/5px; }

.pestPage .item3 {
  margin-top: 40px;
  margin-bottom: 50px; }
  @media (max-width: 991px) {
    .pestPage .item3 {
      margin-top: 70px; } }
  .pestPage .item3 .it3-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .pestPage .item3 .it3-list li {
      display: flex;
      align-items: center;
      margin-bottom: 75px;
      width: 33%; }
      @media (min-width: 991px) {
        .pestPage .item3 .it3-list li:nth-child(3n-1) {
          transition-delay: .3s; }
        .pestPage .item3 .it3-list li:nth-child(3n) {
          transition-delay: .6s; } }
      @media (max-width: 991px) {
        .pestPage .item3 .it3-list li {
          width: 49%;
          margin-bottom: 25px; } }
      .pestPage .item3 .it3-list li .l {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        font-size: 1.375rem;
        line-height: 1.4;
        letter-spacing: 0px;
        font-weight: 700;
        color: #fff;
        margin-right: 10.5%;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media (max-width: 991px) {
          .pestPage .item3 .it3-list li .l {
            font-size: 0.9375rem;
            min-width: 65px;
            min-height: 65px;
            width: 65px;
            height: 65px;
            margin-right: 6.5%;
            line-height: 1.3; } }
      .pestPage .item3 .it3-list li .r .bl-des {
        font-size: 1.375rem;
        line-height: 1.3;
        letter-spacing: 0px;
        font-weight: 700;
        color: #244981;
        font-family: "Noto Sans TC"; }
        @media (max-width: 991px) {
          .pestPage .item3 .it3-list li .r .bl-des {
            font-size: 0.9375rem; } }
      .pestPage .item3 .it3-list li .r .des {
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 0px;
        font-weight: 400;
        color: #333; }
        @media (max-width: 991px) {
          .pestPage .item3 .it3-list li .r .des {
            font-size: 0.8125rem;
            line-height: 1.5; } }
      .pestPage .item3 .it3-list li:nth-child(1) .l {
        background-color: #ea9a03; }
      .pestPage .item3 .it3-list li:nth-child(2) .l {
        background-color: #00a29a; }
      .pestPage .item3 .it3-list li:nth-child(3) .l {
        background-color: #cacc43; }
      .pestPage .item3 .it3-list li:nth-child(4) .l {
        background-color: #7a6a56; }
      .pestPage .item3 .it3-list li:nth-child(5) .l {
        background-color: #ee7743; }
      .pestPage .item3 .it3-list li:nth-child(6) .l {
        background-color: #601986; }
      .pestPage .item3 .it3-list li:nth-child(7) .l {
        background-color: #244981; }
      .pestPage .item3 .it3-list li:nth-child(8) .l {
        background-color: #9fa0a0; }
      .pestPage .item3 .it3-list li:nth-child(9) .l {
        background-color: #a40b5d; }

.serpestPage #top-menu-ul, .sersterPage #top-menu-ul, .serformPage #top-menu-ul, .serproPage #top-menu-ul {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .serpestPage #top-menu-ul, .sersterPage #top-menu-ul, .serformPage #top-menu-ul, .serproPage #top-menu-ul {
      margin-top: 35px; } }

.serpestPage #top-menu-ul .item_menu_Box li a, .sersterPage #top-menu-ul .item_menu_Box li a, .serformPage #top-menu-ul .item_menu_Box li a, .serproPage #top-menu-ul .item_menu_Box li a {
  padding: 19px 76px; }
  @media (max-width: 767px) {
    .serpestPage #top-menu-ul .item_menu_Box li a, .sersterPage #top-menu-ul .item_menu_Box li a, .serformPage #top-menu-ul .item_menu_Box li a, .serproPage #top-menu-ul .item_menu_Box li a {
      padding: 11px 22px; } }

.shblbg-ti {
  font-size: 1.875rem;
  line-height: 1.33;
  letter-spacing: 0px;
  font-weight: 700;
  color: #244981;
  width: 100%;
  padding: 20px 0;
  background-color: #eff6ff;
  text-align: center; }
  @media (max-width: 767px) {
    .shblbg-ti {
      padding: 13px 0;
      font-size: 1.25rem; } }

.serpestPage .item1 .sh-insdtit .ti:before {
  right: -66px; }
  @media (max-width: 767px) {
    .serpestPage .item1 .sh-insdtit .ti:before {
      right: -23px; } }

.serpestPage .item1 .it1-bx .org-ti {
  font-size: 1.875rem;
  line-height: 1.33;
  letter-spacing: 0px;
  font-weight: 700;
  color: #ea9a03;
  text-align: center;
  margin-bottom: 38px;
  margin-top: 72px; }
  @media (max-width: 767px) {
    .serpestPage .item1 .it1-bx .org-ti {
      font-size: 1.25rem;
      margin-top: 36px;
      margin-bottom: 19px; } }

.serpestPage .item1 .it1-list {
  margin-top: 62px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .serpestPage .item1 .it1-list {
      display: block; } }
  @media (max-width: 767px) {
    .serpestPage .item1 .it1-list {
      margin-top: 31px;
      margin-bottom: 30px; } }
  .serpestPage .item1 .it1-list li {
    position: relative;
    width: 31%; }
    @media (max-width: 991px) {
      .serpestPage .item1 .it1-list li {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 25px; } }
    @media (max-width: 767px) {
      .serpestPage .item1 .it1-list li {
        width: 80%; } }
    .serpestPage .item1 .it1-list li .bgcover {
      background-size: cover !important;
      padding-bottom: 480px; }
      @media (max-width: 767px) {
        .serpestPage .item1 .it1-list li .bgcover {
          padding-bottom: 360px; } }
    .serpestPage .item1 .it1-list li .tx-bx {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: 80%; }
      @media (max-width: 767px) {
        .serpestPage .item1 .it1-list li .tx-bx {
          top: 120px; } }
      .serpestPage .item1 .it1-list li .tx-bx .num {
        font-size: 5rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 300;
        color: #ffffff;
        position: relative;
        padding-bottom: 45px;
        margin-bottom: 45px; }
        @media (max-width: 767px) {
          .serpestPage .item1 .it1-list li .tx-bx .num {
            font-size: 3.75rem;
            margin-bottom: 25px;
            padding-bottom: 25px; } }
        .serpestPage .item1 .it1-list li .tx-bx .num::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          max-width: 60px;
          width: 50%;
          height: 3px;
          background-color: #fff; }
          @media (max-width: 767px) {
            .serpestPage .item1 .it1-list li .tx-bx .num::before {
              max-width: 40px;
              height: 2px; } }
      .serpestPage .item1 .it1-list li .tx-bx .des {
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 0px;
        font-weight: 400;
        color: #ffffff; }
        @media (max-width: 767px) {
          .serpestPage .item1 .it1-list li .tx-bx .des {
            font-size: 0.875rem; } }

.serpestPage .item2 {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .serpestPage .item2 {
      margin-top: 35px;
      margin-bottom: 20px; } }
  .serpestPage .item2 .it2-bx .it2-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 70px; }
    @media (max-width: 767px) {
      .serpestPage .item2 .it2-bx .it2-list {
        margin-top: 35px; } }
    .serpestPage .item2 .it2-bx .it2-list li {
      width: 22%;
      text-align: center;
      margin-bottom: 110px; }
      @media (min-width: 767px) {
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(1) {
          transition-delay: .3s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(2) {
          transition-delay: .6s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(3) {
          transition-delay: .9s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(4) {
          transition-delay: 1.2s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(5) {
          transition-delay: 1.5s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(6) {
          transition-delay: 1.8s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(7) {
          transition-delay: 2.1s; }
        .serpestPage .item2 .it2-bx .it2-list li:nth-child(8) {
          transition-delay: 2.4s; } }
      @media (max-width: 767px) {
        .serpestPage .item2 .it2-bx .it2-list li {
          width: 46%;
          margin-bottom: 50px; }
          .serpestPage .item2 .it2-bx .it2-list li .img-bx {
            width: 40%;
            margin: 0 auto; } }
      .serpestPage .item2 .it2-bx .it2-list li .des {
        font-size: 1.875rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #ea9a03;
        margin-top: 28px; }
        @media (max-width: 767px) {
          .serpestPage .item2 .it2-bx .it2-list li .des {
            font-size: 1.25rem; } }

.sersterPage .sh-insdtit .ti:before {
  right: -76px; }
  @media (max-width: 767px) {
    .sersterPage .sh-insdtit .ti:before {
      right: 14px; } }

.sersterPage .item1 .it1-list {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  margin-top: 65px; }
  @media (max-width: 991px) {
    .sersterPage .item1 .it1-list {
      width: 100%; } }
  @media (max-width: 767px) {
    .sersterPage .item1 .it1-list {
      display: block;
      margin-top: 35px; } }
  .sersterPage .item1 .it1-list li {
    width: 32%;
    padding-left: 5%; }
    .sersterPage .item1 .it1-list li:nth-child(1) {
      transition-delay: .3s; }
    .sersterPage .item1 .it1-list li:nth-child(2) {
      transition-delay: .6s; }
    .sersterPage .item1 .it1-list li:nth-child(3) {
      transition-delay: .9s; }
    @media (max-width: 991px) {
      .sersterPage .item1 .it1-list li {
        padding-left: 0; } }
    @media (max-width: 767px) {
      .sersterPage .item1 .it1-list li {
        width: 100%;
        padding-left: 0;
        display: flex;
        align-items: center;
        padding-bottom: 25px;
        margin-bottom: 25px; } }
    .sersterPage .item1 .it1-list li:not(:last-child) {
      border-right: 1px solid #dfdbdb; }
      @media (max-width: 767px) {
        .sersterPage .item1 .it1-list li:not(:last-child) {
          border-right: none;
          border-bottom: 1px solid #dfdbdb; } }
    @media (max-width: 767px) {
      .sersterPage .item1 .it1-list li .img-bx {
        width: 31%; } }
    .sersterPage .item1 .it1-list li .des-bx {
      padding-left: 15px; }
      @media (max-width: 767px) {
        .sersterPage .item1 .it1-list li .des-bx {
          padding-left: 9%;
          width: 69%; } }
      .sersterPage .item1 .it1-list li .des-bx .ti {
        font-size: 1.375rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #244981;
        margin-top: 30px;
        margin-bottom: 25px; }
        @media (max-width: 767px) {
          .sersterPage .item1 .it1-list li .des-bx .ti {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 0.9375rem; } }
      .sersterPage .item1 .it1-list li .des-bx .des {
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 0px;
        font-weight: 400;
        color: #333; }
        @media (max-width: 767px) {
          .sersterPage .item1 .it1-list li .des-bx .des {
            font-size: 0.875rem;
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px; }
            .sersterPage .item1 .it1-list li .des-bx .des div {
              width: 50%; } }

.sersterPage .item2 {
  position: relative;
  margin-top: 100px;
  background: url(../images/service/b_bg.jpg) no-repeat center center/cover;
  background-position-y: 45px; }
  @media (max-width: 767px) {
    .sersterPage .item2 {
      margin-top: 0;
      background: url(../images/service/b_bg_mb.jpg) no-repeat center center/cover;
      background-position-y: 35px; } }
  .sersterPage .item2::before {
    position: absolute;
    content: '';
    top: 45px;
    left: 0;
    width: 100%;
    height: calc(100% - 45px);
    z-index: -1; }
    @media (max-width: 767px) {
      .sersterPage .item2::before {
        top: 35px;
        height: calc(100% - 35px); } }
  .sersterPage .item2 .sh-morebtn {
    max-width: 366px;
    width: 80%;
    height: 96px;
    margin: 0; }
    @media (max-width: 767px) {
      .sersterPage .item2 .sh-morebtn {
        max-width: 220px;
        height: 60px;
        margin-left: 15px; } }
    .sersterPage .item2 .sh-morebtn span {
      line-height: 4.8; }
      @media (max-width: 767px) {
        .sersterPage .item2 .sh-morebtn span {
          line-height: 3.4; } }
    .sersterPage .item2 .sh-morebtn::after {
      display: none; }
    .sersterPage .item2 .sh-morebtn:hover span {
      color: #fff !important; }
  .sersterPage .item2 .it2-list {
    padding-bottom: 20px;
    margin-top: 85px; }
    @media (max-width: 767px) {
      .sersterPage .item2 .it2-list {
        margin-top: 45px; } }
    .sersterPage .item2 .it2-list li {
      display: flex;
      margin-bottom: 50px; }
      @media (max-width: 767px) {
        .sersterPage .item2 .it2-list li {
          display: block;
          margin-bottom: 30px; } }
      .sersterPage .item2 .it2-list li .img-bx {
        width: 44%; }
        @media (max-width: 767px) {
          .sersterPage .item2 .it2-list li .img-bx {
            width: 100%; } }
        .sersterPage .item2 .it2-list li .img-bx .bgcover {
          padding-bottom: 300px;
          background-size: cover !important; }
          @media (max-width: 767px) {
            .sersterPage .item2 .it2-list li .img-bx .bgcover {
              padding-bottom: 52%; } }
      .sersterPage .item2 .it2-list li .tx-bx {
        width: 56%; }
        @media (max-width: 767px) {
          .sersterPage .item2 .it2-list li .tx-bx {
            width: 100%; } }
        .sersterPage .item2 .it2-list li .tx-bx .ti {
          font-size: 1.875rem;
          line-height: 2;
          letter-spacing: 0px;
          font-weight: 300;
          color: #244981;
          width: 80%;
          margin: 0 auto; }
          @media (max-width: 767px) {
            .sersterPage .item2 .it2-list li .tx-bx .ti {
              width: 100%;
              font-size: 1.125rem; } }
          .sersterPage .item2 .it2-list li .tx-bx .ti span {
            font-size: 2.5rem;
            margin-left: 5px; }
            @media (max-width: 767px) {
              .sersterPage .item2 .it2-list li .tx-bx .ti span {
                font-size: 2.1875rem;
                margin-left: 0; } }
        .sersterPage .item2 .it2-list li .tx-bx .sh-des {
          width: 80%;
          margin: 0 auto; }
          @media (max-width: 767px) {
            .sersterPage .item2 .it2-list li .tx-bx .sh-des {
              width: 100%; } }
      .sersterPage .item2 .it2-list li:nth-child(1) .img-bx {
        order: 2; }
      .sersterPage .item2 .it2-list li:nth-child(1) .tx-bx {
        order: 1;
        margin-left: -20px; }
        @media (max-width: 767px) {
          .sersterPage .item2 .it2-list li:nth-child(1) .tx-bx {
            margin-left: 0; } }

.sersterPage .item3 {
  margin-top: 50px;
  margin-bottom: 115px; }
  @media (max-width: 767px) {
    .sersterPage .item3 {
      margin-top: 25px;
      margin-bottom: 75px; } }
  .sersterPage .item3 .bl-ti {
    font-size: 1.375rem;
    line-height: 2;
    letter-spacing: 0px;
    font-weight: 700;
    color: #244981;
    margin-bottom: 7px; }
    @media (max-width: 767px) {
      .sersterPage .item3 .bl-ti {
        font-size: 1.125rem;
        margin-bottom: 0px; } }
    .sersterPage .item3 .bl-ti span {
      font-size: 1.125rem;
      font-weight: 400; }
      @media (max-width: 767px) {
        .sersterPage .item3 .bl-ti span {
          font-size: 0.9375rem; } }
  .sersterPage .item3 .card1 {
    margin-bottom: 11px; }
    @media (max-width: 767px) {
      .sersterPage .item3 .card1 {
        margin-bottom: 25px; } }
  .sersterPage .item3 .card2 {
    margin-bottom: 90px; }
    @media (max-width: 767px) {
      .sersterPage .item3 .card2 {
        margin-bottom: 45px; } }
  .sersterPage .item3 .card3 {
    margin-top: 52px;
    margin-bottom: 70px; }
    .sersterPage .item3 .card3 .sh-des:first-child {
      margin-bottom: 35px; }
    @media (max-width: 767px) {
      .sersterPage .item3 .card3 {
        margin-top: 26px;
        margin-bottom: 45px; } }
  .sersterPage .item3 .card4 .bl-ti {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-align: center; }
    @media (max-width: 767px) {
      .sersterPage .item3 .card4 .bl-ti {
        margin-bottom: 15px;
        font-size: 1.125rem;
        text-align: left; } }
  .sersterPage .item3 .it3-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 75px; }
    @media (max-width: 767px) {
      .sersterPage .item3 .it3-list {
        margin-top: 37px; } }
    .sersterPage .item3 .it3-list li {
      width: 25%; }
      @media (min-width: 767px) {
        .sersterPage .item3 .it3-list li:nth-child(1) {
          transition-delay: .3s; }
        .sersterPage .item3 .it3-list li:nth-child(2) {
          transition-delay: .6s; }
        .sersterPage .item3 .it3-list li:nth-child(3) {
          transition-delay: .9s; }
        .sersterPage .item3 .it3-list li:nth-child(4) {
          transition-delay: 1.2s; } }
      @media (max-width: 767px) {
        .sersterPage .item3 .it3-list li {
          width: 50%;
          text-align: center;
          margin-bottom: 20px; } }
      .sersterPage .item3 .it3-list li .des {
        margin-top: 20px;
        font-size: 1.125rem;
        line-height: 2;
        letter-spacing: 0px;
        font-weight: 400;
        color: #333333;
        font-family: "Noto Sans TC"; }
        @media (max-width: 767px) {
          .sersterPage .item3 .it3-list li .des {
            font-size: 0.8125rem;
            line-height: 1.8; } }
      @media (max-width: 767px) {
        .sersterPage .item3 .it3-list li .img-bx {
          width: 30%;
          margin: 0 auto; } }
  @media (max-width: 767px) {
    .sersterPage .item3 .shblbg-ti {
      padding: 30px 0; } }
  .sersterPage .item3 .shblbg-ti span {
    font-weight: 400; }
  .sersterPage .item3 .mt.shblbg-ti {
    margin-top: 40px;
    font-size: 1.5rem; }
    @media (max-width: 767px) {
      .sersterPage .item3 .mt.shblbg-ti {
        font-size: 1.125rem;
        padding: 50px 15px;
        margin-top: 10px; } }

.serformPage .item1 {
  margin-bottom: 115px; }
  @media (max-width: 991px) {
    .serformPage .item1 {
      margin-bottom: 15px; } }
  .serformPage .item1 .des1 {
    max-width: 1030px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 55px; }
    @media (max-width: 991px) {
      .serformPage .item1 .des1 {
        margin-top: 25px;
        padding: 0;
        text-align: justify; } }
  .serformPage .item1 .it1-bgcover {
    width: 100%;
    margin-bottom: 55px; }
    @media (max-width: 767px) {
      .serformPage .item1 .it1-bgcover {
        margin-bottom: 40px; } }
    .serformPage .item1 .it1-bgcover .bgcover {
      width: 100%;
      padding-bottom: 328px;
      background-size: cover !important; }
      @media (max-width: 991px) {
        .serformPage .item1 .it1-bgcover .bgcover {
          padding-bottom: 47.5%; } }
  .serformPage .item1 .it1-list {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: flex-end; }
    @media (max-width: 1199px) {
      .serformPage .item1 .it1-list {
        width: 100%; } }
    @media (max-width: 991px) {
      .serformPage .item1 .it1-list {
        flex-wrap: wrap; } }
    .serformPage .item1 .it1-list li {
      width: 25%;
      text-align: center; }
      .serformPage .item1 .it1-list li:nth-child(1) {
        transition-delay: .3s; }
      .serformPage .item1 .it1-list li:nth-child(2) {
        transition-delay: .6s; }
      .serformPage .item1 .it1-list li:nth-child(3) {
        transition-delay: .9s; }
      .serformPage .item1 .it1-list li:nth-child(4) {
        transition-delay: 1.2s; }
      @media (max-width: 991px) {
        .serformPage .item1 .it1-list li {
          width: 50%;
          margin-bottom: 60px; }
          .serformPage .item1 .it1-list li:nth-child(1) {
            position: relative; }
            .serformPage .item1 .it1-list li:nth-child(1)::before {
              position: absolute;
              content: '';
              bottom: -30px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #dfdbdb; }
          .serformPage .item1 .it1-list li:nth-child(2) {
            position: relative; }
            .serformPage .item1 .it1-list li:nth-child(2)::before {
              position: absolute;
              content: '';
              bottom: -30px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #dfdbdb; }
          .serformPage .item1 .it1-list li:nth-child(2) {
            border-right: none !important; } }
      .serformPage .item1 .it1-list li:not(:last-child) {
        border-right: 1px solid #dfdbdb; }
      .serformPage .item1 .it1-list li .ti {
        font-size: 1.875rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #ea9a03;
        margin-top: 35px;
        margin-bottom: 25px; }
        @media (max-width: 767px) {
          .serformPage .item1 .it1-list li .ti {
            font-size: 1.25rem;
            margin-top: 22px;
            margin-bottom: 12px; } }
      @media (max-width: 767px) {
        .serformPage .item1 .it1-list li .img-bx {
          width: 33%;
          margin: 0 auto; } }
      @media (max-width: 767px) {
        .serformPage .item1 .it1-list li .sh-des {
          font-size: 0.8125rem; } }
      @media (max-width: 767px) {
        .serformPage .item1 .it1-list li .sh-des br {
          display: block; } }

@media (min-width: 1200px) {
  .serproPage .container {
    max-width: 1004px; } }

@media (min-width: 1200px) {
  .serproPage .item1 .container {
    max-width: 1200px; } }

.serproPage .flex-bx {
  display: flex;
  justify-content: space-between; }
  .serproPage .flex-bx .l.active.in_fade .arr {
    height: calc(100% - 270px); }
    @media (max-width: 991px) {
      .serproPage .flex-bx .l.active.in_fade .arr {
        height: calc(100% - 220px); } }
    @media (max-width: 767px) {
      .serproPage .flex-bx .l.active.in_fade .arr {
        height: calc(100% - 140px); } }
  .serproPage .flex-bx .l {
    width: 20.5%;
    text-align: center;
    position: relative; }
    @media (max-width: 767px) {
      .serproPage .flex-bx .l {
        width: 29.5%; } }
    .serproPage .flex-bx .l .arr {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 17px;
      top: 270px;
      height: 0;
      transition-delay: .4s;
      transition: 1s ease; }
      @media (max-width: 991px) {
        .serproPage .flex-bx .l .arr {
          top: 220px; } }
      @media (max-width: 767px) {
        .serproPage .flex-bx .l .arr {
          top: 140px; } }
      .serproPage .flex-bx .l .arr::before {
        position: absolute;
        content: '';
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 1px;
        height: calc(100% - 22px);
        background-color: #ea9a03; }
        @media (max-width: 767px) {
          .serproPage .flex-bx .l .arr::before {
            height: calc(100% - 16px); } }
      .serproPage .flex-bx .l .arr::after {
        position: absolute;
        content: '';
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        border-top: 17px solid #ea9a03;
        border-left: 9px dashed transparent;
        border-right: 9px dashed transparent; }
        @media (max-width: 767px) {
          .serproPage .flex-bx .l .arr::after {
            border-top: 10px solid #ea9a03;
            border-left: 5px dashed transparent;
            border-right: 5px dashed transparent; } }
    .serproPage .flex-bx .l .bti {
      font-size: 1.875rem;
      line-height: 1.33;
      letter-spacing: 0px;
      font-weight: 700;
      color: #244981;
      margin-top: 20px; }
      @media (max-width: 767px) {
        .serproPage .flex-bx .l .bti {
          font-size: 1.125rem;
          margin-top: 10px; } }
    @media (max-width: 767px) {
      .serproPage .flex-bx .l .img-bx {
        width: 100px;
        height: 100px;
        margin: 0 auto; } }
  .serproPage .flex-bx .r {
    width: 69.5%; }
    @media (max-width: 767px) {
      .serproPage .flex-bx .r {
        width: 65.5%; } }
    .serproPage .flex-bx .r .serpro-list {
      display: flex;
      flex-wrap: wrap; }
      .serproPage .flex-bx .r .serpro-list li {
        width: 28%;
        margin-bottom: 60px; }
        @media (max-width: 767px) {
          .serproPage .flex-bx .r .serpro-list li {
            width: 44%;
            margin-bottom: 25px; } }
        .serproPage .flex-bx .r .serpro-list li:not(:nth-child(3n)) {
          margin-right: 8%; }
          @media (max-width: 767px) {
            .serproPage .flex-bx .r .serpro-list li:not(:nth-child(3n)) {
              margin-right: 0; } }
        @media (max-width: 767px) {
          .serproPage .flex-bx .r .serpro-list li:nth-child(odd) {
            margin-right: 12%; } }
        .serproPage .flex-bx .r .serpro-list li:nth-child(1) {
          transition-delay: .3s; }
        .serproPage .flex-bx .r .serpro-list li:nth-child(2) {
          transition-delay: .6s; }
        .serproPage .flex-bx .r .serpro-list li:nth-child(3) {
          transition-delay: .9s; }
        .serproPage .flex-bx .r .serpro-list li:nth-child(4) {
          transition-delay: 1.2s; }
        .serproPage .flex-bx .r .serpro-list li:nth-child(5) {
          transition-delay: 1.5s; }
        .serproPage .flex-bx .r .serpro-list li .st-bx {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 10px;
          margin-bottom: 22px;
          border-bottom: 2px solid #ea9a03; }
          @media (max-width: 767px) {
            .serproPage .flex-bx .r .serpro-list li .st-bx {
              margin-bottom: 10px;
              padding-bottom: 4px; } }
          .serproPage .flex-bx .r .serpro-list li .st-bx .en {
            font-size: 1.375rem;
            line-height: 1.4;
            letter-spacing: 0px;
            font-weight: 300;
            color: #244981; }
            @media (max-width: 767px) {
              .serproPage .flex-bx .r .serpro-list li .st-bx .en {
                font-size: 0.8125rem; } }
          .serproPage .flex-bx .r .serpro-list li .st-bx .num {
            font-size: 3rem;
            line-height: 1;
            letter-spacing: 0px;
            font-weight: 300;
            color: #244981; }
            @media (max-width: 767px) {
              .serproPage .flex-bx .r .serpro-list li .st-bx .num {
                font-size: 1.875rem; } }
        .serproPage .flex-bx .r .serpro-list li .ti {
          font-size: 1.375rem;
          line-height: 1.4;
          letter-spacing: 0px;
          font-weight: 700;
          color: #244981;
          margin-bottom: 5px; }
          @media (max-width: 767px) {
            .serproPage .flex-bx .r .serpro-list li .ti {
              font-size: 0.9375rem; } }
        .serproPage .flex-bx .r .serpro-list li .des {
          font-size: 1.125rem;
          line-height: 2;
          letter-spacing: 0px;
          font-weight: 400;
          color: #333333; }
          @media (max-width: 767px) {
            .serproPage .flex-bx .r .serpro-list li .des {
              font-size: 0.75rem;
              line-height: 1.35; } }

.serproPage .item2 {
  margin-top: 80px; }
  @media (max-width: 767px) {
    .serproPage .item2 {
      margin-top: 0px; } }

.serproPage .item3 {
  margin-top: 25px; }
  @media (max-width: 767px) {
    .serproPage .item3 {
      margin-top: 15px; } }
  .serproPage .item3 .r .serpro-list li {
    margin-bottom: 180px; }
    @media (max-width: 767px) {
      .serproPage .item3 .r .serpro-list li {
        margin-bottom: 73px; } }

.serproPage .item4 {
  margin-top: 25px;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .serproPage .item4 {
      margin-top: 15px;
      margin-bottom: 75px; } }
  .serproPage .item4 .it4-card {
    width: 100%;
    display: flex;
    margin-top: 62px;
    background-color: #eff6ff;
    align-items: center;
    padding: 50px 58px; }
    @media (max-width: 767px) {
      .serproPage .item4 .it4-card {
        display: block;
        padding: 25px 32px;
        margin-top: 50px; } }
    .serproPage .item4 .it4-card .l {
      font-size: 1.875rem;
      line-height: 1.33;
      letter-spacing: 0px;
      font-weight: 700;
      color: #244981;
      min-width: 186px;
      margin-right: 7.5%; }
      @media (max-width: 767px) {
        .serproPage .item4 .it4-card .l {
          min-width: 100%;
          width: 100%;
          margin-right: 0;
          font-size: 1.125rem;
          margin-bottom: 10px; } }
    .serproPage .item4 .it4-card .r {
      font-size: 1.125rem;
      line-height: 2;
      letter-spacing: 0px;
      font-weight: 400;
      color: #333;
      width: calc(92.5% - 186px); }
      @media (max-width: 767px) {
        .serproPage .item4 .it4-card .r {
          width: 100%;
          font-size: 0.9375rem; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 16px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 20px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

#top-menu-ul {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    #top-menu-ul {
      margin: 0 auto 35px;
      width: calc(100% + 40px);
      left: -20px; }
      #top-menu-ul::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0), #fff);
        z-index: 5; }
      #top-menu-ul::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff);
        z-index: 5; } }
  @media (min-width: 768px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 60px; }
    @media (max-width: 767px) {
      #top-menu-ul .item_Menu {
        height: 40px; } }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: center; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 60px; }
      #top-menu-ul .item_menu_Box li:first-child a {
        margin-left: 19px; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li:first-child a {
          margin-left: 30px; }
        #top-menu-ul .item_menu_Box li:last-child a {
          margin-right: 30px; } }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li {
          height: 40px; } }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0.6px;
      font-size: 1.375rem;
      color: #244981;
      font-weight: 700;
      padding: 19px 85px;
      margin: 0 10px; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 11px 22px;
          font-size: 1.125rem;
          margin: 0 5px;
          min-width: 118px; } }
      #top-menu-ul .item_menu_Box li a::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #eff6ff;
        transform: skewX(-30deg);
        z-index: 3; }
      #top-menu-ul .item_menu_Box li a::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #244981;
        transform: skewX(-30deg);
        z-index: 5;
        transition: .4s ease;
        z-index: 4; }
      #top-menu-ul .item_menu_Box li a div {
        position: relative;
        z-index: 7; }
    #top-menu-ul .item_menu_Box li.active a {
      color: #fff; }
      #top-menu-ul .item_menu_Box li.active a::before {
        background-color: #244981; }
    @media (min-width: 768px) {
      #top-menu-ul .item_menu_Box li a:hover {
        color: #fff; }
        #top-menu-ul .item_menu_Box li a:hover::after {
          width: 100%; } }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    background: url(../images/mbarrow_r.png) no-repeat center/15px;
    right: -60px; }
  .flex-direction-nav a.flex-prev {
    background: url(../images/mbarrow_l.png) no-repeat center/15px;
    left: -60px; }

.open_flexslider .flex-direction-nav {
  display: block; }
  @media (max-width: 991px) {
    .open_flexslider .flex-direction-nav {
      display: none; } }

.sh-titlebx {
  text-align: center; }
  .sh-titlebx .en-ti {
    font-size: 3.75rem;
    line-height: 1;
    letter-spacing: 0px;
    font-weight: 300;
    color: #244981;
    margin-bottom: 22px; }
    @media (max-width: 767px) {
      .sh-titlebx .en-ti {
        font-size: 2.5rem;
        margin-bottom: 20px; } }
  .sh-titlebx .ti {
    font-size: 1.375rem;
    line-height: 1;
    letter-spacing: 0px;
    font-weight: 700;
    color: #ea9a03;
    position: relative;
    display: inline-block; }
    @media (max-width: 767px) {
      .sh-titlebx .ti {
        font-size: 1.125rem; } }
    .sh-titlebx .ti::before {
      position: absolute;
      content: '';
      left: -57px;
      width: 45px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #ea9a03; }
      @media (max-width: 767px) {
        .sh-titlebx .ti::before {
          width: 35px;
          left: -45px; } }
    .sh-titlebx .ti::after {
      position: absolute;
      content: '';
      right: -57px;
      width: 45px;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      background-color: #ea9a03; }
      @media (max-width: 767px) {
        .sh-titlebx .ti::after {
          width: 35px;
          right: -45px; } }

.sh-des {
  font-size: 1.125rem;
  line-height: 2;
  letter-spacing: 0px;
  font-weight: 400;
  color: #333333; }
  @media (max-width: 767px) {
    .sh-des {
      font-size: 0.9375rem;
      line-height: 1.8; }
      .sh-des br {
        display: none; } }

.sh-morebtn {
  width: 325px;
  height: 70px;
  margin: 0 auto;
  display: block;
  position: relative;
  text-align: center;
  transition: .4s ease; }
  @media (max-width: 767px) {
    .sh-morebtn {
      width: 215px;
      height: 46px; } }
  .sh-morebtn span {
    position: relative;
    z-index: 5;
    font-size: 1.375rem;
    line-height: 3.4;
    letter-spacing: 1.1px;
    font-weight: 400;
    color: #fff; }
    @media (max-width: 767px) {
      .sh-morebtn span {
        font-size: 1.125rem;
        line-height: 2.7; } }
  .sh-morebtn::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #244981;
    transform: skewX(-30deg); }
  .sh-morebtn::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #ea9a03;
    transform: skewX(-30deg);
    z-index: 4;
    transition: .4s ease; }
  @media (min-width: 768px) {
    .sh-morebtn:hover {
      color: #244981; }
      .sh-morebtn:hover span {
        color: #244981 !important; }
      .sh-morebtn:hover::after {
        width: 100%; } }

.in_fade.sh-morebtn {
  top: 0;
  opacity: 1; }
  .in_fade.sh-morebtn span {
    color: rgba(255, 255, 255, 0); }
  .in_fade.sh-morebtn::before {
    width: 0;
    transition: .6s ease; }

.in_fade.sh-morebtn.active span {
  color: #fff; }

.in_fade.sh-morebtn.active::before {
  width: 100%;
  transition: .6s ease; }

.sh-inbanner {
  width: 100%;
  position: relative; }
  .sh-inbanner .bgcover {
    background-size: cover !important;
    height: 250px;
    width: 100%; }
    @media (max-width: 767px) {
      .sh-inbanner .bgcover {
        height: 0;
        padding-bottom: 32.5%; } }
  .sh-inbanner .tx-bx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    padding: 0 16px;
    width: 100%;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .sh-inbanner .tx-bx {
        padding: 0 20px; } }
    .sh-inbanner .tx-bx .en-ti {
      font-size: 3rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 300;
      color: #fff; }
      @media (max-width: 767px) {
        .sh-inbanner .tx-bx .en-ti {
          font-size: 1.875rem; } }
    .sh-inbanner .tx-bx .tw-ti {
      font-size: 1.375rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 700;
      color: #fff;
      margin-left: 35px; }
      @media (max-width: 767px) {
        .sh-inbanner .tx-bx .tw-ti {
          font-size: 1.125rem;
          margin-left: 15px; } }

.sh-insdtit {
  text-align: center; }
  .sh-insdtit .en-ti {
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 0px;
    font-weight: 300;
    color: #777777; }
    @media (max-width: 767px) {
      .sh-insdtit .en-ti {
        font-size: 0.8125rem; } }
  .sh-insdtit .ti {
    font-size: 1.875rem;
    line-height: 1.33;
    letter-spacing: 0px;
    font-weight: 700;
    color: #244981;
    position: relative;
    display: inline-block;
    font-family: "Noto Sans TC"; }
    @media (max-width: 767px) {
      .sh-insdtit .ti {
        font-size: 1.25rem; } }
    .sh-insdtit .ti::before {
      position: absolute;
      content: '';
      right: -15px;
      top: -65px;
      background: url(../images/b_title-bg.png) no-repeat center center/contain;
      width: 148px;
      height: 152px;
      z-index: -1; }
      @media (max-width: 767px) {
        .sh-insdtit .ti::before {
          width: 74px;
          height: 76px;
          right: 3px;
          top: -30px; } }

.text-center {
  text-align: center; }

.in_fade {
  opacity: 0;
  transition: 1s ease-in-out;
  top: 20px;
  position: relative; }

.in_fade.active {
  opacity: 1;
  top: 0; }
