.pestintrPage{
    .sh-inbanner .tx-bx .tw-ti{
        @media (max-width:767px) {
            margin-left: 0;
            line-height: 1.3;
        }
    }
    .sh-inbanner .tx-bx .tw-ti{
        @media (max-width:767px) {
            margin-left: 0;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1038px;
        }
    }
    .item1{
        margin-top: 85px;
        @media (max-width:767px) {
            margin-top: 40px;
        }
        .peint-ti{
            min-width: 310px;
            height: 60px;
            display: inline-block;
            position: relative;
            text-align: center;
            padding: 0 35px;
            margin-bottom: 45px;
            margin-left: -45px;
            @media (max-width:767px) {
                min-width: 195px;
                height: 45px;
                margin-left: 15px;
                margin-bottom: 30px;
            }
            &::before{
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                background-color: $hover;
                transform: skewX(-30deg);
            }
            &::after{
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: $org;
                transform: skewX(-30deg);
                z-index: 4;
                transition: .4s ease;
            }
            .t{
                @include fontset(30,1.9,0,700,#fff);
                position: relative;
                z-index: 5;
                @media (max-width:767px) {
                    line-height: 2.3;
                    font-size: pxToEm(20);
                }
                .e{
                    font-size: pxToEm(18);
                    font-weight: 300;
                    font-weight: $inr;
                    text-transform:uppercase;
                    margin-left: 15px;
                    @media (max-width:767px) {
                        font-size: pxToEm(12);
                    }
                }
            }
        }
        .blbg-ti{
            @include fontset(22,1,0,700,$hover);
            text-align: center;
            background-color: #eff6ff;
            padding: 17px 0;
            @media (max-width:767px) {
                font-size: pxToEm(18);
                padding: 17px 15px;
            }
        }
        .editor_Content{
            margin-top: 45px;
            margin-bottom: 100px;
            @media (max-width:767px) {
                margin-top: 30px;
                margin-bottom: 50px;
            }
        }


        .sh-morebtn{
            width: 285px;
            height: 70px;
            margin-bottom: 90px;
            @media (max-width:767px) {
                width: 195px;
                height: 45px;
                margin-bottom: 75px;
            }
            span{
                @media (max-width:767px) {
                    line-height: 2.6;
                }
            }
        }
    }
}


.pestPage{
    .sh-inbanner .tx-bx .tw-ti{
        @media (max-width:767px) {
            margin-left: 0;
            line-height: 1.3;
        }
    }
    .sh-insdtit .ti:before{
        right: -70px;
        @media (max-width:767px) {
            right: -30px;
        }
    }
    .item1{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 40px;
        }
        .it1-bx{
            height: auto;
            margin-top: 75px;
            margin-bottom: 100px;
            border-top: 1px solid #dfdcdc;
            position: relative;
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                height: auto;
            }
            @media (max-width:767px) {
                margin-top: 35px;
                margin-bottom: 50px;
            }
            &::before{
                position: absolute;
                bottom: 0;
                content: '';
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #dfdcdc;
                z-index: 10;
            }
            .l{
                min-width: 295px;
                max-width: 295px;
                @media (max-width:767px) {
                    min-width: 147px;
                    max-width: 147px;
                }
                .m-list{
                    li.active{
                        a{
                            color: #fff;
                            span::after{
                                height: 0;
                            }
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                    li{
                        a{
                            height: 70px;
                            display: block;
                            @include fontset(22,3.2,0,700,$hover);
                            text-align: center;
                            transition: .4s ease;
                            position: relative;
                            overflow: hidden;
                            @media (max-width:767px) {
                                font-size: pxToEm(14);
                                height: 35px;
                                line-height: 2.5;
                            }
                            span{
                                position: relative;
                                z-index: 5;
                            }
                            &::before{
                                position: absolute;
                                content: '';
                                right: 0;
                                height: 0;
                                width: 0;
                                border-bottom: 71px solid #fff;
                                border-right: 0 dashed transparent;
                                border-left: 50px dashed transparent;
                                z-index: 6;
                                @media (max-width:767px) {
                                    border-bottom: 35px solid #fff;
                                    border-right: 0 dashed transparent;
                                    border-left: 38px dashed transparent;
                                }
                            }
                            &::after{
                                position: absolute;
                                content: '';
                                top: 0;
                                left: -25px;
                                width: 0;
                                height: 100%;
                                background-color: $hover;
                                transition: .6s ease;
                                transform: skewX(-35deg);
                                z-index: 4;
                            }
                            @media (min-width:768px) {
                                &:hover{
                                    color: #fff;
                                    span::after{
                                        height: 0;
                                    }
                                    &::after{
                                        width: 100%;
                                    }
                                }
                            }
                            span{
                                position: relative;
                                padding-right: 50px;
                                left: -10px;
                                @media (max-width:767px) {
                                    padding-right: 25px;
                                }
                                &::before{
                                    position: absolute;
                                    content: '';
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 15px;
                                    height: 1px;
                                    background-color: #dddddd;
                                }
                                &::after{
                                    position: absolute;
                                    content: '';
                                    right: 7px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 1px;
                                    height: 15px;
                                    background-color: #dddddd;
                                }
                            }
                        }
                        &:not(:last-child){
                            position: relative;
                            &::before{
                                position: absolute;
                                content: '';
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: #dfdcdc;
                            }
                        }
                    }
                }
            }
            .r{
                width: calc(100% - 147px);
                position: relative;
                .p-list{
                    width: 90%;
                    margin: 0 auto;
                    @media (max-width:991px) {
                        margin-top: 15px;
                    }
                    li.active{
                        opacity: 1;
                        pointer-events: all;
                        display: flex;
                        @media (max-width:991px) {
                            display: block;
                        }
                    }
                    li{
                        position: relative;
                        margin-top: 25px;
                        margin-bottom: 25px;
                        left: 0;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        opacity: 0;
                        pointer-events: none;
                        display: none;
                        @media (max-width:991px) {
                            margin-top: 12px;
                            margin-bottom: 0px;
                        }
                        .it{
                            width: 38%;
                            display: flex;
                            // align-items: center;
                            margin: 0;
                            margin-left: 10%;
                            margin-bottom: 35px;
                            @media (max-width:991px){
                                margin-bottom: 20px;
                                width: 100%;
                            }
                            &:last-child{
                                margin-bottom: 0;
                                @media (max-width:991px){
                                    margin-bottom: 20px;
                                }
                            }
                            &:nth-last-child(2){
                                margin-bottom: 0;
                                @media (max-width:991px){
                                    margin-bottom: 30px;
                                }
                            }
                            .img-bx{
                                margin-right: 15px;
                                @media (max-width:767px){
                                    width: 35%;
                                }
                            }
                            .des-bx{
                                max-width: 135px;
                                padding-top: 12px;
                                width: 135px;
                                @media (max-width:767px){
                                    max-width: 75px;
                                    width: 75px;
                                    padding-top: 2px;
                                }
                                .ti{
                                    @include fontset(22,1.4,0,400,$hover);
                                    margin-bottom: 4px;
                                    @media (max-width:767px){
                                        font-size: pxToEm(14);
                                    }
                                }
                                .en-ti{
                                    @include fontset(18,1.4,0,300,#777);
                                    @media (max-width:767px){
                                        font-size: pxToEm(12);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .item2.in_fade.active{
        .it2-bx .r .line-bx{
            .line1{
                width: 49.5%;
            }
            .line2{
                width: 90.75%;
            }
            .line3{
                width: 57.75%;
            }
            .line4{
                .l1{
                    width: 24.75%;
                }
                .l2{
                    width: 16.5%;
                }
            }
            .line5{
                width: 57.75%;
            }
            .line6{
                width: 66%;
            }
            .line7{
                width: 49.5%;
            }
            .line8{
                .l1{
                    width: 24.75%;
                }
                .l2{
                    width: 24.75%;
                }
            }
            .line9{
                width: 57.75%;
            }
            
        }
    }
    .item2{
        .blbg-ti{
            width: 100%;
            padding: 19px 0;
            text-align: center;
            @include fontset(30,1.3,0,700,$hover);
            background-color: #eff6ff;
            margin-bottom: 55px;
            @media (max-width:767px) {
                font-size: pxToEm(20);
                padding: 12px 0;
                margin-bottom: 30px;
            }
        }
        .it2-obx{
            position: relative;
            overflow-x: scroll;
            overflow-y: hidden;
            width: 100%;
        }
        .it2-bx{
            display: flex;
            height: 503px;
            @media (max-width:1199px) {
                width: 928px;
                position: relative;
            }
            @media (max-width:991px){
                height: 370px;
            }
            .l{
                width: 125px;
                padding-top: 90px;
                @media (max-width:991px){
                    width: 85px;
                    padding-top: 67px;
                }
                ul{
                    li{
                        @include fontset(22,1,0,400,$hover);
                        @media (max-width:991px){
                            font-size: pxToEm(14);
                        }
                        &:not(:last-child){
                            margin-bottom: 25px;
                            @media (max-width:991px){
                                margin-bottom: 17px;
                            }
                        }
                    }
                }
            }
            .r{
                width: calc(100% - 125px);
                @media (max-width:991px){
                    margin-left: -50px;
                    width: calc(90% - 35px);
                }
                ul{
                    display: flex;
                    li{
                        width: 8.25%;
                        text-align: right;
                        @include fontset(20,1,0,400,$hover);
                        position: relative;
                        @media (max-width:991px){
                            font-size: pxToEm(16);
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            right: 14px;
                            top: 28px;
                            height: 475px;
                            width: 1px;
                            background-color: #dfdcdc;
                            z-index: -1;
                        }
                    }
                }
                .line-bx{
                    margin-top: 74px;
                    @media (max-width:991px){
                        margin-top: 53px;
                    }
                    .line,.l1,.l2{
                        transition:1s ease;
                        height: 15px;
                        @media (max-width:991px){
                            height: 11px;
                        }
                    }
                    .line1{
                        margin-left: calc(33% - 14px);
                        // width: 49.5%;
                        background-color: #ea9a03;
                        width: 0;
                        
                    }
                    .line2{
                        margin-left: calc(8.25% - 14px);
                        // width: 90.75%;
                        background-color: #00a29a;
                        width: 0;
                        transition-delay: .3s;
                    }
                    .line3{
                        margin-left: calc(24.75% - 14px);
                        // width: 57.75%;
                        background-color: #cacc43;
                        width: 0;
                        transition-delay: .6s;
                    }
                    .line4{
                        display: flex;
                        .l1{
                            margin-left: calc(33% - 14px);
                            // width: 24.75%;
                            background-color: #7a6a56;
                            width: 0;
                            transition-delay: .9s;
                        }
                        .l2{
                            background-color: #7a6a56;
                            // width: 16.5%;
                            margin-left: 16.5% ;
                            width: 0;
                            transition-delay: .9s;
                        }
                    }
                    .line5{
                        margin-left: calc(24.75% - 14px);
                        // width: 57.75%;
                        background-color: #ee7743;
                        width: 0;
                        transition-delay: 1.2s;
                    }
                    .line6{
                        margin-left: calc(24.75% - 14px);
                        // width: 66%;
                        width: 0;
                        background-color: #601986;
                        transition-delay: 1.5s;
                    }
                    .line7{
                        margin-left: calc(33% - 14px);
                        // width: 49.5%;
                        width: 0;
                        background-color: #244981;
                        transition-delay: 1.8s;
                    }
                    .line8{
                        display: flex;
                        .l1{
                            margin-left: calc(8.25% - 14px);
                            // width: 24.75%;
                            width: 0;
                            background-color: #9fa0a0;
                            transition-delay: 2.1s;
                        }
                        .l2{
                            background-color: #9fa0a0;
                            // width: 24.75%;
                            width: 0;
                            margin-left: 41.25% ;
                            transition-delay: 2.1s;
                        }
                    }
                    .line9{
                        margin-left: calc(24.75% - 14px);
                        // width: 57.75%;
                        width: 0;
                        background-color: #a40b5d;
                        transition-delay: 2.4s;
                    }
                    .line{
                       
                        &:not(:last-child){
                            margin-bottom: 32px;
                            @media (max-width:991px){
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
                
            }
        }
        .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
            background-color: #aaaaaa;
            border-radius: 0;
            height: 3px;
        }
        .mCSB_scrollTools .mCSB_draggerRail{
            background-color: #eeeeee;
            height: 3px;
        }
        .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft{
            opacity: 1;
            background: url(../images/mCSB_buttons.png) no-repeat center center / 5px;
            top: -2px;
        }
        .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight{
            opacity: 1;
            top: -2px;
            transform: rotate(180deg);
            background: url(../images/mCSB_buttons.png) no-repeat center center / 5px;
        }
    }
    .item3{
        margin-top: 40px;
        margin-bottom: 50px;
        @media (max-width:991px){
            margin-top: 70px;
        }
        .it3-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                display: flex;
                align-items: center;
                margin-bottom: 75px;
                width: 33%;
                @media (min-width:991px) {
                    &:nth-child(3n-1){
                        transition-delay: .3s ;
                    }
                    &:nth-child(3n){
                        transition-delay: .6s ;
                    }
                }
                @media (max-width:991px) {
                    width: 49%;
                    margin-bottom: 25px;
                }

                .l{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    @include fontset(22,1.4,0,700,#fff);
                    margin-right: 10.5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width:991px) {
                        font-size: pxToEm(15);
                        min-width: 65px;
                        min-height: 65px;
                        width: 65px;
                        height: 65px;
                        margin-right: 6.5%;
                        line-height: 1.3;
                    }
                }
                .r{
                    .bl-des{
                        @include fontset(22,1.3,0,700,$hover);
                        font-family: $font_content;
                        @media (max-width:991px) {
                            font-size: pxToEm(15);
                        }
                    }
                    .des{
                        @include fontset(18,2,0,400,#333);
                        @media (max-width:991px) {
                            font-size: pxToEm(13);
                            line-height: 1.5;
                        }
                    }
                }
                &:nth-child(1){
                    .l{
                        background-color: #ea9a03;
                    }
                }
                &:nth-child(2){
                    .l{
                        background-color: #00a29a;
                    }
                }
                &:nth-child(3){
                    .l{
                        background-color: #cacc43;
                    }
                }
                &:nth-child(4){
                    .l{
                        background-color: #7a6a56;
                    }
                }
                &:nth-child(5){
                    .l{
                        background-color: #ee7743;
                    }
                }
                &:nth-child(6){
                    .l{
                        background-color: #601986;
                    }
                }
                &:nth-child(7){
                    .l{
                        background-color: #244981;
                    }
                }
                &:nth-child(8){
                    .l{
                        background-color: #9fa0a0;
                    }
                }
                &:nth-child(9){
                    .l{
                        background-color: #a40b5d;
                    }
                }
            }
        }
    }
}

