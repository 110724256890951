footer{
    z-index : -10;
    padding: 50px 55px 42px 60px;
    background-color: $hover;
    @media (max-width:767px) {
        padding: 23px 20px;
        padding-bottom: 85px;
        
    }
    .f-bx{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        @media (max-width:767px) {
            display: block;
        }
        .l{
            width: 88%;
            @media (max-width:767px) {
                width: 100%;
            }
            .flex-bx{
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                @media (max-width:767px) {
                    display: block;
                }
                .t{
                    margin-right: 3.5%;
                   .f-logo{
                        width: 337px;
                        margin-bottom: 20px;
                        @media (max-width:767px) {
                            width: 240px;
                            margin-bottom: 10px;
                        }
                   } 
                   .env{
                        @include fontset(18,1.91,0,400,#fff);
                        @media (max-width:767px) {
                            font-size: pxToEm(13);
                        }
                   }
                   .copyright,.mak{
                        @include fontset(14,1.4,0,400,#ffffff);
                   }
                }
                .link-bx{
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    @media (max-width:1250px) {
                        margin-bottom: -20px;
                    }
                  
                    a{
                        padding: 20px 30px;
                        border: 1px solid #fff;
                        text-align: center;
                        border-radius: 30px;
                        display: block;
                        @media (max-width:1250px) {
                            margin-bottom: 20px;
                        }
                        @media (max-width:767px) {
                            padding: 10px 10px;
                            margin-bottom: 15px;
                        }
                        @media (min-width:768px) {
                            &:hover{
                                background-color: #fff;
                                span{
                                    color: $hover;
                                }
                               
                            }
                        }
                        span{
                            @include fontset(18,.5,0,400,#ffffff);
                            @media (max-width:767px) {
                                font-size: pxToEm(14);
                            }
                        }
                        &:not(:last-child){
                            margin-right: 20px;
                            @media (max-width:767px) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
        .r{
            width: auto;
            @media (max-width:767px) {
                margin-top: 35px;
            }
            .g-bx{
                display: flex;
                a{
                    width: 40px;
                    display: block;
                    position: relative;
                    @media (max-width:767px) {
                        width: 32px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($hover,.4);
                        opacity: 0;
                        transition: .4s ease;
                    }
                    &:not(:last-child){
                        margin-right: 20px;
                        @media (max-width:767px) {
                            margin-right: 35px;
                        }
                    }
                    &:first-child{
                        width: 53px;
                        @media (max-width:767px) {
                            width: 43px;
                        }
                    }
                    @media (min-width:768px) {
                        &:hover{

                            &::before{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.f-telbtn{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 55px;
    @include fontset(22,2.8,0,700,$hover);
    background-color: $org;
    text-align: center;
    z-index: 999;
    span{
        position: relative;
        padding-left: 50px;
        @include fontset(22,2.8,0,700,$hover);
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
        }
    }
}

