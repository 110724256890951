.serpestPage,.sersterPage,.serformPage,.serproPage{
    #top-menu-ul{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 35px;
        }
    }
    #top-menu-ul .item_menu_Box li a{
        padding: 19px 76px;
        @media (max-width:767px) {
            padding: 11px 22px;
        }
    }
}

.shblbg-ti{
    @include fontset(30,1.33,0,700,$hover);
    width: 100%;
    padding:20px 0 ;
    background-color: #eff6ff;
    text-align: center;
    @media (max-width:767px) {
        padding:13px 0 ;
        font-size: pxToEm(20);
    }
}

.serpestPage{
    .item1{
        .sh-insdtit .ti:before{
            right: -66px;
            @media (max-width:767px) {
                right: -23px;
            }
        }
        .it1-bx{
            .org-ti{
                @include fontset(30,1.33,0,700,$org);
                text-align: center;
                margin-bottom: 38px;
                margin-top: 72px;
                @media (max-width:767px) {
                    font-size: pxToEm(20);
                    margin-top: 36px;
                    margin-bottom: 19px;
                }
            }

        }
        .it1-list{
            margin-top: 62px;
            margin-bottom: 60px;
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
            @media (max-width:767px) {
                margin-top: 31px;
                margin-bottom: 30px;
            }
            li{
                position: relative;
                width: 31%;
                @media (max-width:991px) {
                    width: 80%;
                    margin: 0 auto;
                    margin-bottom: 25px;
                }
                @media (max-width:767px) {
                    width: 80%;
                }
                .bgcover{
                    background-size: cover !important;
                    padding-bottom: 480px;
                    @media (max-width:767px) {
                        padding-bottom: 360px;
                    }
                }
                .tx-bx{
                    position: absolute;
                    top: 150px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    width: 80%;
                    @media (max-width:767px) {
                        top: 120px;
                    }
                    .num{
                        @include fontset(80,1,0,300,#ffffff);
                        position: relative;
                        padding-bottom: 45px;
                        margin-bottom: 45px;
                        @media (max-width:767px) {
                            font-size: pxToEm(60);
                            margin-bottom: 25px;
                            padding-bottom: 25px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            max-width: 60px;
                            width: 50%;
                            height: 3px;
                            background-color: #fff;
                            @media (max-width:767px) {
                                max-width: 40px;
                                height: 2px;
                            }
                        }
                    }
                    .des{
                        @include fontset(18,2,0,400,#ffffff);
                        @media (max-width:767px) {
                            font-size: pxToEm(14);
                        }
                    }
                }
            }
        }
    }
    .item2{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 35px;
            margin-bottom: 20px;
        }
        .it2-bx{
           
            .it2-list{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-end;
                margin-top: 70px;
                @media (max-width:767px) {
                    margin-top: 35px;
                }
                li{
                    width: 22%;
                    text-align: center;
                    margin-bottom: 110px;
                    @media (min-width:767px){
                        &:nth-child(1){
                            transition-delay: .3s;
                        }
                        &:nth-child(2){
                            transition-delay: .6s;
                        }
                        &:nth-child(3){
                            transition-delay: .9s;
                        }
                        &:nth-child(4){
                            transition-delay: 1.2s;
                        }
                        &:nth-child(5){
                            transition-delay: 1.5s;
                        }
                        &:nth-child(6){
                            transition-delay: 1.8s; 
                        }
                        &:nth-child(7){
                            transition-delay: 2.1s;
                        }
                        &:nth-child(8){
                            transition-delay: 2.4s;
                        }
                    }
                   
                    @media (max-width:767px) {
                        width: 46%;
                        margin-bottom: 50px;
                        .img-bx{
                            width: 40%;
                            margin: 0 auto;
                        }
                    }
                    .des{
                        @include fontset(30,1,0,700,$org);
                        margin-top: 28px;
                        @media (max-width:767px) {
                            font-size: pxToEm(20);
                        }

                    }
                }
            }
        }
    }
}


.sersterPage{
    .sh-insdtit .ti:before{
        right: -76px;
        @media (max-width:767px) {
            right: 14px;
        }
    }
    .item1{
        .it1-list{
            display: flex;
            justify-content: space-between;
            width: 85%;
            margin: 0 auto;
            margin-top: 65px;
            @media (max-width:991px) {
                width: 100%;
            }
            @media (max-width:767px) {
                display: block;
                margin-top: 35px;
            }
            li{
                width: 32%;
                padding-left: 5%;
                &:nth-child(1){
                    transition-delay: .3s;
                }
                &:nth-child(2){
                    transition-delay: .6s;
                }
                &:nth-child(3){
                    transition-delay: .9s;
                }
                @media (max-width:991px) {
                    padding-left: 0;
                }
                @media (max-width:767px) {
                    width: 100%;
                    padding-left: 0;
                    display: flex;
                    align-items: center;
                    padding-bottom: 25px;
                    margin-bottom: 25px;
                }
                &:not(:last-child){
                    border-right: 1px solid #dfdbdb;
                    @media (max-width:767px) {
                        border-right: none;
                        border-bottom: 1px solid #dfdbdb;
                    }
                }
                .img-bx{
                    @media (max-width:767px) {
                        width: 31%;
                    }
                }
                .des-bx{
                    padding-left: 15px;
                    @media (max-width:767px) {
                        padding-left: 9%;
                        width: 69%;
                    }
                    .ti{
                        @include fontset(22,1,0,700,$hover);
                        margin-top: 30px;
                        margin-bottom: 25px;
                        @media (max-width:767px) {
                            margin-top: 10px;
                            margin-bottom: 5px;
                            font-size: pxToEm(15);
                        }
                    }
                    .des{
                        @include fontset(18,2,0,400,#333);
                        @media (max-width:767px) {
                            font-size: pxToEm(14);
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 15px;
                            div{
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }   
    }
    .item2{
        position: relative;
        margin-top: 100px;
        background: url(../images/service/b_bg.jpg) no-repeat center center / cover;
        background-position-y: 45px;
        @media (max-width:767px) {
            margin-top: 0;
            background: url(../images/service/b_bg_mb.jpg) no-repeat center center / cover;
            background-position-y: 35px;
        }
        &::before{
            position: absolute;
            content: '';
            top: 45px;
            left: 0;
            width: 100%;
            height: calc(100% - 45px);
            z-index: -1;
            
            @media (max-width:767px) {
                top: 35px;
                height: calc(100% - 35px);
               
            }
        }
        .sh-morebtn{
            max-width: 366px;
            width: 80%;
            height: 96px;
            margin: 0;
            @media (max-width:767px) {
                max-width: 220px;
                height: 60px;
                margin-left: 15px;
            }
            span{
                line-height: 4.8;
                @media (max-width:767px) {
                    line-height: 3.4;
                }
            }
            &::after{
                display: none;
            }
            &:hover{
                span{
                    color: #fff !important;
                }
            }
        }
        .it2-list{
            padding-bottom: 20px;
            margin-top: 85px;
            @media (max-width:767px) {
                margin-top: 45px;
            }
            li{
                display: flex;
                margin-bottom: 50px;
                @media (max-width:767px) {
                    display: block;
                    margin-bottom: 30px;
                }
                .img-bx{
                    width: 44%;
                    @media (max-width:767px) {
                        width: 100%;
                    }
                    .bgcover{
                        padding-bottom: 300px;
                        background-size: cover !important;
                        @media (max-width:767px) {
                            padding-bottom: 52%;
                        }
                    }
                }
                .tx-bx{
                    width: 56%;
                    @media (max-width:767px) {
                        width: 100%;
                    }
                    .ti{
                        @include fontset(30,2,0,300,$hover);
                        width: 80%;
                        margin: 0 auto;
                        @media (max-width:767px) {
                            width: 100%;
                            font-size: pxToEm(18);
                        }
                        span{
                            font-size: pxToEm(40);
                            margin-left: 5px;
                            @media (max-width:767px) {
                                font-size: pxToEm(35);
                                margin-left: 0;
                            }
                        }
                    }
                    .sh-des{
                        width: 80%;
                        margin: 0 auto;
                        @media (max-width:767px) {
                            width: 100%;
                        }
                    }
                }
                &:nth-child(1){
                    .img-bx{
                        order: 2;
                    }
                    .tx-bx{
                        order: 1;
                        margin-left: -20px;
                        @media (max-width:767px) {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    .item3{
        margin-top: 50px;
        margin-bottom: 115px;
        @media (max-width:767px) {
            margin-top: 25px;
            margin-bottom: 75px;
        }
        .bl-ti{
            @include fontset(22,2,0,700,$hover);
            margin-bottom: 7px;
            @media (max-width:767px) {
                font-size: pxToEm(18);
                margin-bottom: 0px;
            }
            span{
                font-size: pxToEm(18);
                font-weight: 400;
                @media (max-width:767px) {
                    font-size: pxToEm(15);
                }
            }
        }
        .card1{
            margin-bottom: 11px;
            @media (max-width:767px) {
                margin-bottom: 25px;
            }
        }
        .card2{
            margin-bottom: 90px;
            @media (max-width:767px) {
                margin-bottom: 45px;
            }
        }
        .card3{
            margin-top: 52px;
            margin-bottom: 70px;
            .sh-des:first-child{
                margin-bottom: 35px;
            }
            @media (max-width:767px) {
                margin-top: 26px;
                margin-bottom: 45px;
            }
        }
        .card4{
            .bl-ti{
                font-size: pxToEm(24);
                margin-bottom: 30px;
                text-align: center;
                @media (max-width:767px) {
                    margin-bottom: 15px;
                    font-size: pxToEm(18);
                    text-align: left;
                }
            }
        }
        .it3-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 75px;
            @media (max-width:767px) {
                margin-top: 37px;
            }
            li{
                width: 25%;
                @media (min-width:767px){
                    &:nth-child(1){
                        transition-delay: .3s;
                    }
                    &:nth-child(2){
                        transition-delay: .6s;
                    }
                    &:nth-child(3){
                        transition-delay: .9s;
                    }
                    &:nth-child(4){
                        transition-delay: 1.2s;
                    }
                }
               
                @media (max-width:767px) {
                    width: 50%;
                    text-align: center;
                    margin-bottom: 20px;
                }
                .des{
                    margin-top: 20px;
                    @include fontset(18,2,0,400,#333333);
                    font-family: $font_content;
                    @media (max-width:767px) {
                        font-size: pxToEm(13);
                        line-height: 1.8;
                    }
                }
                .img-bx{
                    @media (max-width:767px) {
                        width: 30%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .shblbg-ti{
            @media (max-width:767px) {
                padding: 30px 0;
            }
            span{
                font-weight: 400;
            }
        }
        .mt.shblbg-ti{
            margin-top: 40px;
            font-size: pxToEm(24);
            @media (max-width:767px) {
                font-size: pxToEm(18);
                padding: 50px 15px;
                margin-top: 10px;
            }
        }
    }
}

.serformPage{
    .item1{
        margin-bottom: 115px;
        @media (max-width:991px) {
            margin-bottom: 15px;
        }
        .des1{
            max-width: 1030px;
            width: 100%;
            margin: 0 auto;
            padding: 0 16px;
            text-align: center;
            margin-top: 45px;
            margin-bottom: 55px;
            @media (max-width:991px) {
                margin-top: 25px;
                padding: 0;
                text-align: justify;
            }
        }
        .it1-bgcover{
            width: 100%;
            margin-bottom: 55px;
            @media (max-width:767px) {
                margin-bottom: 40px;
            }
            .bgcover{
                width: 100%;
                padding-bottom: 328px;
                background-size: cover !important;
                @media (max-width:991px) {
                    padding-bottom: 47.5%;
                }
            }
        }
        .it1-list{
            display: flex;
            width: 90%;
            margin: 0 auto;
            align-items: flex-end;
            @media (max-width:1199px) {
                width: 100%;
            }
            @media (max-width:991px) {
                flex-wrap: wrap;
            }
            li{
                width: 25%;
                text-align: center;
                &:nth-child(1){
                    transition-delay: .3s;
                }
                &:nth-child(2){
                    transition-delay: .6s;
                }
                &:nth-child(3){
                    transition-delay: .9s;
                }
                &:nth-child(4){
                    transition-delay: 1.2s;
                }
                @media (max-width:991px) {
                    width: 50%;
                    margin-bottom: 60px;
                    &:nth-child(1){
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: -30px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #dfdbdb;
                        }
                    }
                    &:nth-child(2){
                        position: relative;
                        &::before{
                            position: absolute;
                            content: '';
                            bottom: -30px;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: #dfdbdb;
                        }
                    }
                    &:nth-child(2){
                        border-right: none !important;
                    }
                }
                &:not(:last-child){
                    border-right: 1px solid #dfdbdb;
                }
                .ti{
                    @include fontset(30,1,0,700,$org);
                    margin-top: 35px;
                    margin-bottom: 25px;
                    @media (max-width:767px) {
                        font-size: pxToEm(20);
                        margin-top: 22px;
                        margin-bottom: 12px;
                    }
                }
                .img-bx{
                    @media (max-width:767px) {
                        width: 33%;
                        margin: 0 auto;
                    }
                }
                .sh-des{
                    @media (max-width:767px) {
                        font-size: pxToEm(13);
                    }
                    br{
                        @media (max-width:767px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}


.serproPage{
    .container{
        @media (min-width:1200px) {
            max-width: 1004px;
        }
    }
    .item1{
        .container{
            @media (min-width:1200px) {
                max-width: 1200px;
            }
        }
    }
    .item2 .flex-bx .l.active.in_fade{
        .arr{

        }
    }
    .flex-bx{
        display: flex;
        justify-content: space-between;
        .l.active.in_fade{
            .arr{
                height: calc(100% - 270px);
                @media (max-width:991px) {
                    height: calc(100% - 220px);
                }
                @media (max-width:767px) {
                    height: calc(100% - 140px);
                }
            }
        }
        .l{
            width: 20.5%;
            text-align: center;
            position: relative;
            @media (max-width:767px) {
                width: 29.5%;
            }
            .arr{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 17px;
                top: 270px;
                height: 0;
                transition-delay: .4s;
                transition: 1s ease;
                @media (max-width:991px) {
                    top: 220px;
                    // height: calc(100% - 220px);
                }
                @media (max-width:767px) {
                    top: 140px;
                    // height: calc(100% - 140px);
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0;
                    width: 1px;
                    height:  calc(100% - 22px);
                    background-color: $org;
                    @media (max-width:767px) {
                        height:  calc(100% - 16px);
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 0;
                    border-top: 17px solid #ea9a03;
                    border-left: 9px dashed transparent;
                    border-right: 9px dashed transparent;
                    @media (max-width:767px) {
                        border-top: 10px solid #ea9a03;
                        border-left: 5px dashed transparent;
                        border-right: 5px dashed transparent;
                    }
                }
            }
            .bti{
                @include fontset(30,1.33,0,700,$hover);
                margin-top: 20px;
                @media (max-width:767px) {
                    font-size: pxToEm(18);
                    margin-top: 10px;
                }
            }
            .img-bx{
                @media (max-width:767px) {
                    width: 100px;
                    height: 100px;
                    margin: 0 auto;
                }
            }
        }
        .r{
            width: 69.5%;
            @media (max-width:767px) {
                width: 65.5%;
            }
            .serpro-list{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 28%;
                    @media (max-width:767px) {
                        width: 44%;
                        margin-bottom: 25px;
                    }
                    &:not(:nth-child(3n)){
                        margin-right: 8%;
                        @media (max-width:767px) {
                            margin-right: 0;
                        }
                    }
                    &:nth-child(odd){
                        @media (max-width:767px) {
                            margin-right: 12%;
                        }
                    }
                    margin-bottom: 60px;
                    &:nth-child(1){
                        transition-delay: .3s ;
                    }
                    &:nth-child(2){
                        transition-delay: .6s;
                    }
                    &:nth-child(3){
                        transition-delay: .9s;
                    }
                    &:nth-child(4){
                        transition-delay: 1.2s;
                    }
                    &:nth-child(5){
                        transition-delay: 1.5s;
                    }
                    .st-bx{
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding-bottom: 10px;
                        margin-bottom: 22px;
                        border-bottom: 2px solid $org;
                        @media (max-width:767px) {
                            margin-bottom: 10px;
                            padding-bottom: 4px;
                        }
                        .en{
                            @include fontset(22,1.4,0,300,$hover);
                            @media (max-width:767px) {
                                font-size: pxToEm(13);
                            }
                        }
                        .num{
                            @include fontset(48,1,0,300,$hover);
                            @media (max-width:767px) {
                                font-size: pxToEm(30);
                            }
                        }
                    }
                    .ti{
                        @include fontset(22,1.4,0,700,$hover);
                        margin-bottom: 5px;
                        @media (max-width:767px) {
                            font-size: pxToEm(15);
                        }
                    }
                    .des{
                        @include fontset(18,2,0,400,#333333);
                        @media (max-width:767px) {
                            font-size: pxToEm(12);
                            line-height: 1.35;
                        }
                    }
                }
            }
        }
    }
    .item2{
        margin-top: 80px;
        @media (max-width:767px) {
            margin-top: 0px;
        }
    }
    .item3{
        margin-top: 25px;
        @media (max-width:767px) {
            margin-top: 15px;
        }
        .r .serpro-list li{
            margin-bottom: 180px;
            @media (max-width:767px) {
                margin-bottom: 73px;
            }
        }
    }
    .item4{
        margin-top: 25px;
        margin-bottom: 100px;
        @media (max-width:767px) {
            margin-top: 15px;
            margin-bottom: 75px;
        }
        .it4-card{
            width: 100%;
            display: flex;
            margin-top: 62px;
            background-color: #eff6ff;
            align-items: center;
            padding: 50px 58px;
            @media (max-width:767px) {
                display: block;
                padding: 25px 32px;
                margin-top: 50px;
            }
            .l{
                @include fontset(30,1.33,0,700,$hover);
                min-width: 186px;
                margin-right: 7.5%;
                @media (max-width:767px) {
                    min-width: 100%;
                    width: 100%;
                    margin-right: 0;
                    font-size: pxToEm(18);
                    margin-bottom: 10px;
                }
            }
            .r{
                @include fontset(18,2,0,400,#333);
                width: calc(92.5% - 186px);
                @media (max-width:767px) {
                    width: 100%;
                    font-size: pxToEm(15);
                }
            }
        }
    }
}






