.indexPage{
    .banner-bx{
        width: 100%;
        height: auto;
        overflow: hidden;
        .bgcover{
            height: 0;
            background-size: cover !important;
            padding-bottom: 31.25%;
            @media (max-width:767px) {
                padding-bottom: 162%;
            }
        }
        a{
            display: block;
        }
        .slick-dots{
            text-align: center;
            right: 0;
            bottom: 25px;
            @media (max-width:767px) {
                text-align: center;
                right: auto;
                bottom: 75px;
            }
            li.slick-active{
                button:before{
                    background-color: #ffffff;
                }
            }
            li{
                width: 40px;
                height: 40px !important;
                margin: 0 5px;
                button{
                    width: 40px;
                    height: 40px !important;
                    padding: 0;
                    &::before{
                        width: 15px;
                        height: 15px;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        left: 50%;
                        border-radius: 50%;
                        background-color: rgba(255,255,255,0);
                        transition: .4s;
                        border: 2px solid #ffffff;
                    }
                }
            }
        }
        .slick-prev{
            width: 25px;
            height: 60px;
            left: 55px;
            background: url(../images/arrow_l.png) no-repeat center center / contain;
            @media (max-width:767px) {
                display: none !important;
            }
        }
        .slick-next{
            width: 25px;
            height: 60px;
            right: 55px;
            background: url(../images/arrow_r.png) no-repeat center center / contain;
            @media (max-width:767px) {
                display: none !important;
            }
        }
    }
    .item1{
        margin-top: 95px;
        @media (max-width:767px) {
            margin-top: 45px;
        }
        .sh-des{
            text-align: center;
            margin-top: 25px;
            margin-bottom: 45px;
            @media (max-width:767px) {
                margin-top: 15px;
                margin-bottom: 35px;
                text-align: center;
                br{
                    display: block;
                }
            }
        }
        .it1-list{
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            @media (max-width:767px) {
                margin-top: 40px;
            }
            li{
                width: 16.66666%;
                margin-bottom: 60px;
                @media (max-width:767px) {
                    width: 50%;
                    margin-bottom: 30px;
                }
                @media (min-width:767px) {
                    &:nth-child(1){
                    
                    }
                    &:nth-child(2){
                        transition-delay: .2s;
                    }
                    &:nth-child(3){
                        transition-delay: .4s;
                    }
                    &:nth-child(4){
                        transition-delay: .6s;
                    }
                    &:nth-child(5){
                        transition-delay: .8s;
                    }
                    &:nth-child(6){
                        transition-delay: 1s;
                    }
                    &:nth-child(7){
                        transition-delay: 1.2s;
                    }
                    &:nth-child(8){
                        transition-delay: 1.4s;
                    }
                    &:nth-child(9){
                        transition-delay: 1.6s;
                    }
                    &:nth-child(10){
                        transition-delay: 1.8s;
                    }
                    &:nth-child(11){
                        transition-delay: 2s;
                    }
                    &:nth-child(12){
                        transition-delay: 2.2s;
                    }
                }
               
                .img-bx{
                    position: relative;
                    text-align: center;
                    @media (max-width:767px) {
                        width: 50%;
                        margin: 0 auto;
                    }
                    .hov{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        transition: .4s ease;
                        opacity: 0;
                    }
                }
                .des{
                    @include fontset(22,1,0,400,$hover);
                    text-align: center;
                    transition: .4s ease;
                    margin-top: 25px;
                    @media (max-width:767px) {
                        font-size: pxToEm(18);
                        margin-top: 17px;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        .img-bx{
                            .hov{
                                opacity: 1;
                            }
                        }
                        .des{
                            color: $org;
                        }
                    }
                }
            }
        }
        .tw-bx{
            display: flex;
            margin-top: 90px;
            @media (max-width:767px) {
                margin-top: 20px;
                display: block;
            }
            a{
                display: block;
                position: relative;
                width: 50%;
                @media (min-width:768px) {
                    &:hover{
                        &::before{
                            opacity: 0;
                        }
                    }
                }
                @media (max-width:767px) {
                    width: 100%;
                }
                &::before{
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: .4s ease;
                    background-color: rgba(#244981,.7);
                }
                .bgcover{
                    height: 250px;
                    @media (max-width:767px) {
                        height: 125px;
                        background-size: cover !important;
                    }
                }
                .tx-bx{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 75%;
                    padding-left: 90px;
                    &::before{
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        border-left: 24px solid #ea9a03;
                        border-top: 12px dashed transparent;
                        border-bottom: 12px dashed transparent;
                        @media (max-width:767px) {
                            border-left: 12px solid #ea9a03;
                            border-top: 6px dashed transparent;
                            border-bottom: 6px dashed transparent;
                        }
                    }
                    @media (max-width:767px) {
                        padding-left: 55px;
                    }
                    img{
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        @media (max-width:767px) {
                            width: 12%;
                        }
                    }
                    .en-des,.des{
                        @include fontset(22,1.64,1.1,400,#fff);
                        @media (max-width:767px) {
                            font-size: pxToEm(18);
                            line-height: 1.3;
                        }
                    }
                }
                &:nth-child(2){
                    .tx-bx{
                        padding-left: 100px;
                        @media (max-width:767px) {
                            padding-left: 55px;
                        }
                        img{
                            width: 62px;
                            height: 62px;
                            @media (max-width:767px) {
                                width: 12%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .item2{
        margin-top: 150px;
        overflow: hidden;
        position: relative;
        @media (max-width:767px) {
            margin-top: 50px;
        }
        &::before{
            position: absolute;
            content: '';
            background: url(../images/c_bg.jpg) no-repeat center center / cover;
            height: calc(100% - 55px);
            top: 20px;
            width: 100%;
            z-index: -1;
            @media (max-width:767px) {
                top: 25px;
                height: calc(100% - 45px);
                background: url(../images/c_bg_mb.jpg) no-repeat center center / cover;
            }
        }
        .it2-list{
            margin-top: 95px;
            margin-bottom: 100px;
            width: calc(100% + 40px);
            left: -20px;
            @media (max-width:767px) {
                left: auto;
                width: 86%;
                margin: 0 auto;
                margin-top: 10px;
                margin-bottom: 20px;
            }
            li{
                margin: 0 18px;
                padding: 30px 0;
                transition: .4s;
                .img-bx{
                    margin-bottom: -1px;
                }
                
                .des{
                    @include fontset(20,1.3,1,400,#ffffff);
                    text-align:center;
                    padding: 20px 0;
                    background-color: $hover;
                    transition: .4s ease;
                    @media (max-width:767px) {
                        padding: 15px 0;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        transform: scale(1.15);
                        .des{
                            background-color: $org;
                            color: $hover;
                        }
                    }
                }
            }
            .slick-prev{
                width: 25px;
                height: 60px;
                left: 55px;
                background: url(../images/arrow_l.png) no-repeat center center / contain;
                @media (max-width:767px) {
                    // display: none !important;
                    height: 30px;
                    left: -15px;
                    background: url(../images/mbarrow_l.png) no-repeat center center / contain;
                }
            }
            .slick-next{
                width: 25px;
                height: 60px;
                right: 55px;
                background: url(../images/arrow_r.png) no-repeat center center / contain;
                @media (max-width:767px) {
                    // display: none !important;
                    height: 30px;
                    right: -15px;
                    background: url(../images/mbarrow_r.png) no-repeat center center / contain;
                }
            }
            .slick-track{
                margin: 0 auto;
            }
        }
        .in_fade.sh-morebtn{
            opacity: 1;
            top: 0;
            span{
                color: rgba(255,255,255,0);
                transition: .4s ease;
            }
            &::before{
                width: 0;
                transition: .6s ease;
            }
        }
        .in_fade.sh-morebtn.active{
            opacity: 1;
            top: 0;
            &::before{
                width: 100%;
            }
            span{
                color: #fff;
            }
        }
    }
    .item3{
        margin-top: 135px;
        margin-bottom: 180px;
        @media (max-width:767px) {
            margin-top: 60px;
            margin-bottom: 40px;
        }
        .it3-bx{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width:991px) {
                display: block;
                position: relative;
                padding-top: 95px;
            }
            .t{
                order: 2;
                width: 53.5%;
                @media (max-width:991px) {
                    width: 100%;
                }
            }
            .b{
                order: 1;
                width: 43%;
                margin-bottom: 15px;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 40px;
                }
                .ti-bx{
                    margin-bottom: 50px;
                    @media (max-width:991px) {
                        position: absolute;
                        top: 0;
                        text-align: center;
                        width: 100%;
                    }
                    .en-ti{
                        @include fontset(60,1,0,300,$hover);
                        margin-bottom: 22px;
                        @media (max-width:767px) {
                            font-size: pxToEm(40);
                            margin-bottom: 10px;
                        }
                    }
                    .ti{
                        @include fontset(22,1,0,700,$org);
                        position: relative;
                        @media (max-width:767px) {
                            font-size: pxToEm(18);
                        }
                    }
                }
                .sh-des{
                    margin-bottom: 75px;
                    @media (max-width:767px) {
                        text-align: left;
                        margin-bottom: 30px;
                    }
                }
                .link-bx{
                    display: flex;
                    justify-content: space-between;
                    @media (max-width:767px) {
                        display: flex;
                        padding: 0 7px;
                    }
                }
                .sh-morebtn{
                    width: 269px;
                    height: 96px;
                    padding-top: 18px;
                    margin: 0;
                    &:first-child{
                        margin-right: 20px;
                        @media (max-width:767px) {
                            margin-right:10px ;
                        }
                    }
                    &::before,&::after{
                        @media (max-width:767px) {
                            transform: skewX(-20deg);
                        }
                    }
                    @media (max-width:767px) {
                        height: 75px;
                        padding-top: 12px;
                    }
                    @media (min-width:768px) {
                        &:hover{
                            i{
                                color: $hover;
                            }
                        }
                    }
                    i{
                        @include fontset(22,1.45,0,400,$org);
                        position: relative;
                        z-index: 5;
                        transition: .4s ease;
                        @media (max-width:767px) {
                            font-size: pxToEm(18);
                            letter-spacing: 0;
                        }
                    }
                    span{
                        line-height: 1.5;
                        transition: .4s ease;
                        @media (max-width:767px) {
                            letter-spacing: 0;
                        }
                    }
                }
                .in_fade.sh-morebtn{
                    opacity: 1;
                    top: 0;
                    &::before{
                        width: 0;
                        transition: .6s ease;
                    }
                }
                .in_fade.sh-morebtn.active{
                    &::before{
                        width: 100%;
                    }
                }
            }
        }
    }

    .in_fade.item1.active{

    }
}


.aboutPage{
    .item1{
        margin-top: 100px;
        @media (max-width:767px) {
            margin-top: 30px;
        }
        .it1-bx{
            display: flex;
            justify-content: space-between;
            margin-top: 70px;
            @media (max-width:991px) {
                display: block;
                margin-top: 25px;
            }
            .l{
                width: 54.3%;
                @media (max-width:991px) {
                    width: 100%;
                    margin-bottom: 23px;
                }
                .bgcover{
                    width: 100%;
                    height: 420px;
                    @media (max-width:991px) {
                        height: 0;
                        padding-bottom: 66.66666%;
                        background-size: cover !important;
                    }
                }
            }
            .r{
                width: 40.2%;
                margin-top: -5px;
                margin-bottom: 83px;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 30px;
                }
                .ti{
                    @include fontset(24,1.5,0,700,$org);
                    margin-bottom: 25px;   
                    @media (max-width:767px) {
                        font-size: pxToEm(18);
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    .item2{
        padding-top: 100px;
        position: relative;
        background: url(../images/about/c_bg.jpg) no-repeat center center / 1920px;
        @media (max-width:767px) {
            padding-top: 50px;
            background: url(../images/about/c_bg_mb.jpg) no-repeat center center / cover;
            background-position-y: -30px;
        }
        &::before{
            position: absolute;
            top: 0;
            width: 100%;
            height: calc(100% - 30px);
            content: '';
            // background: url(../images/about/c_bg.jpg) no-repeat center center / cover;
            z-index: -1;
            @media (max-width:767px) {
                // background: url(../images/about/c_bg_mb.jpg) no-repeat center center / cover;
            }
        }
        .it2-bx{
            display: flex;
            justify-content: space-between;
            margin-bottom: 100px;
            @media (max-width:767px) {
                display: block;
                margin-bottom: 75px;
            }
            .l{
                width: 35%;
                @media (max-width:991px) {
                    width: 49%;
                }
                @media (max-width:767px) {
                    width: 100%;
                }
                .bl-ti{
                    @include fontset(30,1.6,0,700,$hover);
                    position: relative;
                    @media (max-width:767px) {
                        font-size: pxToEm(20);
                        margin-bottom: 65px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: -40px;
                        left: 0;
                        width: 208px;
                        height: 5px;
                        background-color: $org;
                        @media (max-width:767px) {
                            bottom: -20px;
                            width: 140px;
                            height: 2px;
                        }
                    }
                }
            }
            .r{
                width: 63.1%;
                @media (max-width:767px) {
                    width: 100%;
                }
                .it2-list{
                    display: flex;
                    justify-content: space-between;
                    li{
                        width: 27.5%;
                        @media (max-width:767px) {
                            width: 29%;
                        }
                        .des{
                            text-align: center;
                            @include fontset(22,1,0,400,$hover);
                            margin-top: 30px;
                            @media (max-width:767px) {
                                font-size: pxToEm(15);
                                margin-top: 15px;
                            }
                        }
                    }
                }
            }
        }
        .sh-morebtn{
            max-width: 523px;
            width: 100%;
            height: 96px;
            margin: 0;
            @media (max-width:767px) {
                max-width: 280px;
                height: 60px;
                margin-left: 10px;
            }
            &::after{
                display: none;
            }
            span{
                line-height: 4.4;
                font-size: pxToEm(24);
                @media (max-width:767px) {
                    font-size: pxToEm(16);
                    line-height: 4.1;
                }
                
            }
            &:hover{
                span{
                    color: #fff !important;
                }
            }
        }
    }
    .item3{
        margin-top: 65px;
        margin-bottom: 120px;
        @media (max-width:991px) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .it3-bx{
            display: flex;
            justify-content: space-between;
            @media (max-width:991px) {
                display: block;
            }
            .t{
                order: 2;
                width: 47%;
                @media (max-width:991px) {
                    width: 100%;
                }
                .bgcover{
                    width: 100%;
                    background-size: cover !important;
                    height: 330px;
                    @media (max-width:991px) {
                        padding-bottom: 60%;
                        height: 0;
                        margin-bottom: 30px;
                    }
                }
            }
            .b{
                order: 1;
                width: 47.5%;
                position: relative;
                margin-top: -5px;
                @media (max-width:991px) {
                    width: 100%;
                    margin-top: 0;
                }
            }
        }
        .ti3-list{
            margin-top: 75px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width:991px) {
                margin-top: 35px;
            }
            li{
                width: 20%;
                text-align: center;
                @media (max-width:1199px) {
                    width: 30%;
                }
                @media (max-width:991px) {
                    width: 33.33333%;
                }
                @media (max-width:767px) {
                    margin-bottom: 30px;
                    .img-bx{
                        width: 45px;
                        margin: 0 auto;
                    }
                    &:nth-child(4){
                        width: 45%;
                    }
                    &:nth-child(5){
                        width: 45%;
                    }
                }
                .des{
                    margin-top: 17px;
                    @include fontset(24,1.5,0,400,#333);
                    @media (max-width:991px) {
                        font-size: pxToEm(15);
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}