/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 20px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1200px;
    }
}

// top-menu
#top-menu-ul{
   
    @media (max-width:767px) {
        margin:0 auto 35px;
        width: calc(100% + 40px);
        left: -20px;
        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(270deg,hsla(0,0%,100%,0),#fff);
            z-index: 5;
        }
        &::after{
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 30px;
            height: 100%;
            background: linear-gradient(90deg,hsla(0,0%,100%,0),#fff);
            z-index: 5;
        }
    }
    @media (min-width:768px) {
        margin:0 auto 50px;
    }
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 60px;
        @media (max-width:767px) {
            height: 40px;
        }
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:center;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 60px;
            &:first-child{
                a{
                    margin-left: 19px;
                }
            }
            @media (max-width:767px) {
                &:first-child{
                    a{
                        margin-left: 30px;
                    }
                }
                &:last-child{
                    a{
                        margin-right: 30px;
                    }
                }
            }
            @media (max-width:767px) {
                height: 40px;
            }
        }
        li a{
            position: relative;
            // overflow: hidden;
            display: block;
            line-height: 1;
            letter-spacing: 0.6px;
            font-size: pxToEm(22);
            color: $hover;
            font-weight: 700;
            padding: 19px 85px;
            margin: 0 10px;
            @media (max-width:767px) {
                padding: 11px 22px;
                font-size: pxToEm(18);
                margin: 0 5px;
                min-width: 118px;
            }
            &::before{
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #eff6ff;
                transform: skewX(-30deg);
                z-index: 3;
            }
            &::after{
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: $hover;
                transform: skewX(-30deg);
                z-index: 5;
                transition: .4s ease;
                z-index: 4;
            }
            div{
                position: relative;
                z-index: 7;
            }
        }
        li.active a{
            color: #fff;
            &::before{
                background-color: $hover;
            }
        }
        li a{
            @media (min-width:768px) {
                &:hover{
                    color: #fff;
                    &::after{
                       width: 100%;
                    }
                }
            }
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        background: url(../images/mbarrow_r.png) no-repeat center / 15px;
        right: -60px;
    }

    a.flex-prev{
        background: url(../images/mbarrow_l.png) no-repeat center / 15px;
        left:  -60px;
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
        @media (max-width:991px) {
            display: none;
        }
    }
}

 ////   popup

//  .pop-act{
//     background: rgba(0, 0, 0, 0.8);
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     z-index: 999;
//     display: none;
//     .vo_signbg{
//         background: url(../images/qr.png) no-repeat;
//         position: relative;
//         width: 300px;
//         height: 300px;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         @media (max-width:992px) {
//             background-size:450px;
//             background-position: center center;
//             width: 450px; 
//         }
//         @media (max-width:576px) {
//             width: 320px;
//             background-size:320px;
//         }
//     }
//     .clos-btn{
//         width: 60px;
//         position: absolute;
//         top: -30px;
//         right: -90px;
//         border-radius: 99px;
//         display: block;
//         height: 20px;
//         cursor: pointer;
//         @media (max-width:576px) {
//             // top: 0px;
//             right: -20px;
//             width: 40px;
//             padding-bottom: 40px;
//         }
//         &:before{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//         &:after{
//             content: '';
//             width: 30px;
//             height: 2px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             // transform: rotate(45deg);
//             transform: translate(-50%,-50%) rotate(-45deg);
//             background: #ffffff;
//             @media (max-width:576px) {
//                 width: 15px;
//             }
//         }
//     }
// }
// .show-pop{
//     display: block !important;
//     }

.sh-titlebx{
    text-align: center;
    .en-ti{
        @include fontset(60,1,0,300,$hover);
        margin-bottom: 22px;
        @media (max-width:767px) {
            font-size: pxToEm(40);
            margin-bottom: 20px;
        }
    }
    .ti{
        @include fontset(22,1,0,700,$org);
        position: relative;
        display: inline-block;
        @media (max-width:767px) {
            font-size: pxToEm(18);
        }
        &::before{
            position: absolute;
            content: '';
            left: -57px;
            width: 45px;
            top: 50%;
            transform: translateY(-50%);
            height: 1px;
            background-color: $org;
            @media (max-width:767px) {
                width: 35px;
                left: -45px;
            }
        }
        &::after{
            position: absolute;
            content: '';
            right: -57px;
            width: 45px;
            top: 50%;
            transform: translateY(-50%);
            height: 1px;
            background-color: $org;
            @media (max-width:767px) {
                width: 35px;
                right: -45px;
            }
        }
    }
}

.sh-des{
    @include fontset(18,2,0,400,#333333);
    @media (max-width:767px) {
        font-size: pxToEm(15);
        line-height: 1.8;
        br{
            display: none;
        }
    }
}

.sh-morebtn{
    width: 325px;
    height: 70px;
    margin: 0 auto;
    display: block;
    position: relative;
    text-align: center;
    transition: .4s ease;
    @media (max-width:767px) {
        width: 215px;
        height: 46px;
    }
    span{
        position: relative;
        z-index: 5;
        @include fontset(22,3.4,1.1,400,#fff);
        @media (max-width:767px) {
            font-size: pxToEm(18);
            line-height: 2.7;
        }
    }
    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        background-color: $hover;
        transform: skewX(-30deg);
    }
    &::after{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $org;
        transform: skewX(-30deg);
        z-index: 4;
        transition: .4s ease;
    }
    @media (min-width:768px) {
        &:hover{
            color: $hover;
            span{
                color: $hover !important;
            }
            &::after{
                width: 100%;
            }
        }
    }
}
.in_fade.sh-morebtn{
    top: 0;
    opacity: 1;
    span{
        color: rgba(255,255,255,0);
    }
    &::before{
        width: 0;
        transition: .6s ease;
    }
}

.in_fade.sh-morebtn.active{
    span{
        color: #fff;
    }
    &::before{
        width: 100%;
        transition: .6s ease;
    }
}



.sh-inbanner{
    width: 100%;
    position: relative;
    .bgcover{
        background-size: cover !important;
        height: 250px;
        width: 100%;
        @media (max-width:767px) {
            height: 0;
            padding-bottom: 32.5%;
        }
    }
    .tx-bx{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 1200px;
        padding: 0 16px;
        width: 100%;
        margin: 0 auto;
        @media (max-width:767px) {
            padding: 0 20px;
        }
        .en-ti{
            @include fontset(48,1,0,300,#fff);
            @media (max-width:767px) {
                font-size: pxToEm(30);
            }
        }
        .tw-ti{
            @include fontset(22,1,0,700,#fff);
            margin-left: 35px;
            @media (max-width:767px) {
                font-size: pxToEm(18);
                margin-left: 15px;
            }
        }
    }
}


.sh-insdtit{
    text-align: center;
    .en-ti{
        @include fontset(20,2,0,300,#777777);
        @media (max-width:767px) {
            font-size: pxToEm(13);
        }
    }
    .ti{
        @include fontset(30,1.33,0,700,$hover);
        position: relative;
        display: inline-block;
        font-family: $font_content;
        @media (max-width:767px) {
            font-size: pxToEm(20);
        }
        &::before{
            position: absolute;
            content: '';
            right: -15px;
            top: -65px;
            background: url(../images/b_title-bg.png) no-repeat center center / contain;
            width: 148px;
            height: 152px;
            z-index: -1;
            @media (max-width:767px) {
                width: 74px;
                height: 76px;
                right: 3px;
                top: -30px;
            }
        }
    }
}

.text-center{
    text-align: center;
}


.in_fade{
    opacity: 0;
    transition: 1s ease-in-out;
    top: 20px;
    position: relative;
}
.in_fade.active{
    opacity: 1;
    top: 0;
}